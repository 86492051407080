import { Injectable } from '@angular/core';
import { CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { ProfileService } from '@rp/services/profile';

@Injectable({ providedIn: 'root' })
export class ProfileGuard implements CanActivateChild {
  constructor(private _profileService: ProfileService) {}

  public canActivate(): Observable<boolean> {
    return this._profileService.resolve();
  }

  public canActivateChild(): Observable<boolean> {
    return this.canActivate();
  }
}
