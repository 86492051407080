import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'environment';
import { StorageService } from '@rp/services/storage';
import { LoggingService } from '@rp/services/logging';
import { ProfileService } from '@rp/services/profile';
import { DateHelperService } from '@rp/services/date-helper';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _route: ActivatedRoute,
    private _storageService: StorageService,
    private _loggingService: LoggingService,
    private _profileService: ProfileService,
  ) {}

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  public login(data): Observable<any> {
    return this._http.post(
      `${environment.apiEndpoint}/tns-layer/auth/v0/login`,
      data,
    );
  }

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  public resetPassword(data): Observable<any> {
    return this._http.post(
      `${environment.apiEndpoint}/tns-layer/auth/v0/reset-password`,
      data,
    );
  }

  public setAuthentication(authResult): void {
    this._loggingService.log('set_authentication', authResult);
    this._storageService.set('accessToken', authResult.accessToken);
    this._storageService.set(
      'expiresAt',
      DateHelperService.now().add(authResult.expiresIn, 'seconds').unix() + '',
    );
    this._navigate();
  }

  public isAuthenticated(): Boolean {
    return DateHelperService.unix() < this._storageService.get('expiresAt');
  }

  public logout(): void {
    if (this.isAuthenticated()) {
      this._profileService.get().subscribe((resp) => {
        const section = resp.sections.find((o) => o.id === 'admin');
        section.values.number_of_logouts =
          section.values.number_of_logouts.length > 0
            ? [section.values.number_of_logouts[0] + 1]
            : [1];

        // might not need this condition?
        if (section) {
          this._profileService.patch({ sections: [section] }).subscribe(() => {
            this._storageService.remove('accessToken');
            this._storageService.remove('expiresAt');
            this._storageService.remove('division_mask');
            this._router.navigate(['/authentication']).then();
          });
        }
      });
    }
  }

  private _navigate(): void {
    const originUrl = this._route.snapshot.queryParams['originUrl'];
    this._router.navigateByUrl(originUrl || '/dashboard');
  }
}
