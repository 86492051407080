import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorMessageService } from '@rp/services/error-message';
import { LoggingService } from '@rp/services/logging';
import { AuthService } from '@rp/services/auth';

@Injectable()
/* eslint-disable @typescript-eslint/no-explicit-any */
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private _authService: AuthService,
    private _errorMessageService: ErrorMessageService,
    private _loggingService: LoggingService,
  ) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status === 401) {
          this._authService.logout();
        }

        let message = [];

        if (error.error?.errorDetails?.length) {
          message = error.error?.errorDetails;
        } else if (error.error?.title) {
          message = [{ message: error.error.title }];
        }

        if (error.status === 0) {
          message = [
            {
              message:
                'Something unexpected has happened. Please try again later.',
            },
          ];
        }

        this._errorMessageService.emit({ state: 'danger', message: message });

        this._loggingService.log('error_interceptor', error);
        return throwError(error);
      }),
    );
  }
}
