import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
/* eslint-disable @typescript-eslint/no-explicit-any */
export class DeviceInterceptor implements HttpInterceptor {
  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // We want to append the user agent to all PATCH to profile
    if (
      request.method === 'PATCH' &&
      request.url.includes('profile/v0/my-profile')
    ) {
      request.body.sections.forEach((section) => {
        section.values[section.id + '_user_agent'] = [this._getDeviceType()];
      });
    }
    return next.handle(request);
  }
  private _getDeviceType = (): string => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'Tablet';
    }
    if (
      /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua,
      )
    ) {
      return 'Mobile';
    }
    return 'Desktop';
  };
}
