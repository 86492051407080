import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as duration from 'dayjs/plugin/duration';
import { DurationUnitType } from 'dayjs/plugin/duration';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as advancedFormat from 'dayjs/plugin/advancedFormat';
import * as utc from 'dayjs/plugin/utc';
import * as tz from 'dayjs/plugin/timezone';
import { ManipulateType } from 'dayjs';
import { DATE_FORMAT } from './date-format';

dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(tz);

@Injectable({
  providedIn: 'root',
})
export class DateHelperService {
  public static now(): dayjs.Dayjs {
    return dayjs();
  }

  public static utcToLocal(d: dayjs.Dayjs | Date | string): dayjs.Dayjs {
    return dayjs.utc(d).local();
  }

  public static today(): dayjs.Dayjs {
    // Formatted to ISO8601.
    return this.toDayjs(this.now().format(DATE_FORMAT.isoDate));
  }

  public static toDayjs(
    d: dayjs.Dayjs | Date | string,
    format?: string,
  ): dayjs.Dayjs {
    return dayjs(d, format);
  }

  public static unix(date?: Date | dayjs.Dayjs | number): number {
    if (date) {
      return dayjs(date).unix();
    }
    return dayjs().unix();
  }

  public static fromUnix(unix: number, format?: string): dayjs.Dayjs {
    return dayjs(dayjs.unix(unix), format);
  }

  public static dayjsToDate(date: dayjs.Dayjs): Date {
    return date.toDate();
  }

  public static subtract(
    date: dayjs.Dayjs,
    value: number,
    unit: ManipulateType,
  ): dayjs.Dayjs {
    return date.subtract(value, unit);
  }

  public static add(
    date: dayjs.Dayjs,
    value: number,
    unit: ManipulateType,
  ): dayjs.Dayjs {
    return date.add(value, unit);
  }

  public static format(date: dayjs.Dayjs, format: string): string {
    return date.format(format);
  }

  public static isBefore(
    date: dayjs.Dayjs,
    anotherDayjs: dayjs.Dayjs,
  ): boolean {
    return date.isBefore(anotherDayjs);
  }

  public static isAfter(date: dayjs.Dayjs, anotherDayjs: dayjs.Dayjs): boolean {
    return date.isAfter(anotherDayjs);
  }

  public static isFutureDate(date: dayjs.Dayjs | number): boolean {
    if (date instanceof Number) {
      return dayjs(date).isAfter(this.now());
    }
    return (date as dayjs.Dayjs).isAfter(this.now());
  }

  public static isPastDate(date: dayjs.Dayjs | number): boolean {
    if (date instanceof Number) {
      return dayjs(date).isBefore(this.now());
    }
    return (date as dayjs.Dayjs).isBefore(this.now());
  }

  public static isBetween(
    date: dayjs.Dayjs,
    start: dayjs.Dayjs,
    end: dayjs.Dayjs,
    rangeCheckStartType: 'exclusive' | 'inclusive' = 'inclusive',
    rangeCheckEndType: 'exclusive' | 'inclusive' = 'inclusive',
  ): boolean {
    const startCheck =
      rangeCheckStartType === 'inclusive'
        ? date.isSame(start) || date.isAfter(start)
        : date.isAfter(start);
    const endCheck =
      rangeCheckEndType === 'inclusive'
        ? date.isSame(end) || date.isBefore(end)
        : date.isBefore(end);
    return startCheck && endCheck;
  }

  public static isValid(date: dayjs.Dayjs): boolean {
    return date.isValid();
  }

  public static duration(
    millis: number,
    unit?: DurationUnitType,
  ): duration.Duration {
    return dayjs.duration(millis, unit);
  }

  public static difference(
    date_from: dayjs.Dayjs,
    date_to: dayjs.Dayjs,
    unit?: DurationUnitType,
  ): number {
    return date_to.diff(date_from, unit);
  }
}
