import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SvgIconComponent } from '@rp/components/svg-icon-component';

@Component({
  selector: 'app-button',
  templateUrl: 'button.component.html',
  imports: [NgClass, MatButtonModule, MatIconModule, SvgIconComponent, NgIf],
  standalone: true,
})
export class ButtonComponent {
  @Input() public text: string;
  @Input() public className: string;
  @Input() public disabled: boolean;
  @Input() public icon: string;
  @Input() public svgIcon: string;
  @Input() public cta: boolean;
  @Input() public ctaLink: boolean;
  @Input() public isMobileApp: boolean;
  @Input() public iconAfterText: boolean;
  @Input() public type = 'submit';
  @Input() public id: string;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public clicked = new EventEmitter<any>();

  public handleClick(event: MouseEvent): void {
    this.clicked.emit(event);
  }
}
