import { Injectable } from '@angular/core';
import { Consultant } from '@rp/models';

@Injectable({
  providedIn: 'root',
})
export class ConsultantService {
  private _consultants: Consultant[] = [
    {
      id: 4,
      name: 'Lorraine Bebbington',
      displayName: 'Lorraine',
      email: 'Lorraine.Bebbington@tnsltd.com',
    },
    {
      id: 11,
      name: 'Sue Baker',
      displayName: 'Sue',
      email: 'Sue.Baker@tnsltd.com',
    },
    {
      id: 18,
      name: 'Michelle Samuels',
      displayName: 'Michelle',
      email: 'Michelle.Samuels@tnsltd.com',
    },
    {
      id: 133,
      name: 'Maxine Christie',
      displayName: 'Maxine',
      email: 'Maxine.christie@nursingguild.com',
    },
    {
      id: 222,
      name: 'Willy Whyatt',
      displayName: 'Willy',
      email: 'William.Whyatt@tnsltd.com',
    },
    {
      id: 232,
      name: 'Simon Clist',
      displayName: 'Simon',
      email: 'Simon.Clist@tnsltd.com',
    },
    {
      id: 234,
      name: 'Sarah Kays',
      displayName: 'Sarah',
      email: 'sarah.kays@thornburynursing.com',
    },
    {
      id: 321,
      name: 'Emma Cook',
      displayName: 'Emma',
      email: 'Emma.Cook@tnsltd.com',
    },
    {
      id: 372,
      name: 'Luke Ball',
      displayName: 'Luke',
      email: 'Luke.Ball@tnsltd.com',
    },
    {
      id: 390,
      name: 'Lucy Cockle',
      displayName: 'Lucy',
      email: 'Lucy.Cockle@tnsltd.com',
    },
    {
      id: 437,
      name: 'Sarah Harper',
      displayName: 'Sarah',
      email: 'Sarah.Harper@tnsltd.com',
    },
    {
      id: 473,
      name: 'Lindsey Wallace',
      displayName: 'Lindsey',
      email: 'Lindsey.Wallace@nursingguild.com',
    },
    {
      id: 492,
      name: 'Samantha Roberts',
      displayName: 'Sam',
      email: 'Samantha.Roberts@tnsltd.com',
    },
    {
      id: 512,
      name: 'Glenys Bower',
      displayName: 'Glenys',
      email: 'Glenys.Blower@tnsltd.com',
    },
    {
      id: 543,
      name: 'Liz Tibbles',
      displayName: 'Liz',
      email: 'Liz.Tibbles@tnsltd.com',
    },
    {
      id: 561,
      name: 'Olga Stamova-Portarska',
      displayName: 'Olly',
      email: 'Olly.Stamova-Portarska@tnsltd.com',
    },
    {
      id: 578,
      name: 'Lewis Smith',
      displayName: 'Lewis',
      email: 'Lewis.Smith@tnsltd.com',
    },
    {
      id: 581,
      name: 'Sandra York',
      displayName: 'Sandie',
      email: 'Sandie.York@tnsltd.com',
    },
    {
      id: 801,
      name: 'Caroline Sime',
      displayName: 'Caroline',
      email: 'Caroline.Sime@tnsltd.com',
    },
    {
      id: 1014,
      name: 'Pav Sales',
      displayName: 'Pav',
      email: 'Pav.Sales@nursingguild.com',
    },
    {
      id: 1016,
      name: 'Marta Murphy',
      displayName: 'Marta',
      email: 'Marta.Murphy@nursingguild.com',
    },
    {
      id: 1080,
      name: 'Alex Lawrence',
      displayName: 'Alex',
      email: 'Alexander.Lawrence@tnsltd.com',
    },
    {
      id: 1099,
      name: 'Joanna Moore',
      displayName: 'Joanna',
      email: 'Joanna.Moore@nursingguild.com',
    },
    {
      id: 2140,
      name: 'Isabel Gavin',
      displayName: 'Isabel',
      email: 'Isabel.Gavin@nursingguild.com',
    },
    {
      id: 2201,
      name: 'Fei Fei Lu',
      displayName: 'Fei Fei',
      email: 'FeiFei.Lu@nursingguild.com',
    },
    {
      id: 2221,
      name: 'Zach Bennett',
      displayName: 'Zach',
      email: 'Zach.Bennett@tnsltd.com',
    },
    {
      id: 2233,
      name: 'Thomas Davis',
      displayName: 'Tom',
      email: 'Tom.Davis@nursingguild.com',
    },
    {
      id: 2263,
      name: 'Penny Sharp',
      displayName: 'Penny',
      email: 'Penny.Sharp@nursingguild.com',
    },
    {
      id: 2277,
      name: 'Kerry Duff',
      displayName: 'Kerry',
      email: 'Kerry.Duff@nursingguild.com',
    },
    {
      id: 2308,
      name: 'Elizabeth Whalen',
      displayName: 'Lizzie',
      email: 'Lizzie.Whalen@tnsltd.com',
    },
    {
      id: 2309,
      name: 'Olga Pietrula',
      displayName: 'Olga',
      email: 'Olga.Pietrula@acaciumgroup.com',
    },
    {
      id: 2316,
      name: 'Viktoriya Leakey',
      displayName: 'Viktoriya',
      email: 'Viktoriya.Leakey@tnsltd.com',
    },
    {
      id: 2326,
      name: 'Courtney Stokes',
      displayName: 'Courtney',
      email: 'Courtney.Stokes@tnsltd.com',
    },
    {
      id: 2344,
      name: 'Marie-Therese Phelan',
      displayName: 'Marie-Therese',
      email: 'Marie-Therese.Phelan@nursingguild.com',
    },
    {
      id: 2345,
      name: 'Caroline Shanahan',
      displayName: 'Caroline',
      email: 'Caroline.Shanahan@nursingguild.com',
    },
    {
      id: 2357,
      name: 'Nathan Caney',
      displayName: 'Nathan',
      email: 'Nathan.Caney@tnsltd.com',
    },
    {
      id: 2359,
      name: 'Chantelle Thomas',
      displayName: 'Chantelle',
      email: 'Chantelle.Thomas@tnsltd.com',
    },
    {
      id: 2360,
      name: 'Kimberly Reid',
      displayName: 'Kim',
      email: 'Kimberly.Reid@nursingguild.com',
    },
    {
      id: 2363,
      name: 'Morgan Knight',
      displayName: 'Morgan',
      email: 'Morgan.Knight@acaciumgroup.com',
    },
    {
      id: 2383,
      name: 'Amanda McGregor',
      displayName: 'Amanda',
      email: 'Amanda.McGregor@nursingguild.com',
    },
    {
      id: 2388,
      name: 'Charlie Brandreth-Potter',
      displayName: 'Charlie',
      email: 'Charli.Brandreth-Potter@tnsltd.com',
    },
    {
      id: 2390,
      name: 'Sarah Isaac',
      displayName: 'Sarah',
      email: 'Sarah.Isaac@tnsltd.com',
    },
    {
      id: 2392,
      name: "Emma O'Brien",
      displayName: 'Emma',
      email: "Emma.O'Brien@nursingguild.com",
    },
    {
      id: 2435,
      name: 'Sebastian Kisiel',
      displayName: 'Sebastian',
      email: 'Sebastian.Kisiel@nursingguild.com',
    },
  ];

  constructor() {}

  public consultant(id): Consultant {
    return this._consultants.find((o) => o.id === id);
  }
}
