import { Inject, Injectable } from '@angular/core';
import { Observable, Observer, of } from 'rxjs';
import { environment } from 'environment';
import { filter, switchMap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { ScopeService } from '@rp/services/scope';

declare const window: {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataLayer: any[];
} & Window;

@Injectable({
  providedIn: 'root',
})
export class TagManagerService {
  constructor(
    @Inject(DOCUMENT) private _doc: Document,
    private _scopeService: ScopeService,
  ) {}

  public pushTag(item: object): void {
    const dataLayer = this._getDataLayer();
    dataLayer.push(item);
  }

  public addGtmToDom(): Observable<boolean> {
    return this._scopeService.data$.pipe(
      filter((data) => !!data),
      switchMap((data) => {
        const scope = data.value === 'tns' ? 'tns' : 'sng';
        const gtmID = environment.production
          ? environment.gtmID?.[scope]
          : environment.gtmID?.tns;

        if (!gtmID) return of(false);
        const isGtmScriptExist = !!this._doc.getElementById('GTMscript');
        if (isGtmScriptExist) return of(true);

        return new Observable((observer: Observer<boolean>) => {
          this._pushOnDataLayer({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
          });

          const gtmScript: HTMLScriptElement =
            this._doc.createElement('script');
          gtmScript.id = 'GTMscript';
          gtmScript.async = true;
          gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${gtmID}`;
          gtmScript.addEventListener('load', () => {
            observer.next(true);
            observer.complete();
          });
          gtmScript.addEventListener('error', () => observer.error(false));
          this._doc.head.insertBefore(gtmScript, this._doc.head.firstChild);
        });
      }),
    );
  }

  public addUserIdToDataLayer(staffId: number): void {
    this._pushOnDataLayer({
      user_id: staffId,
    });
  }

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _getDataLayer(): any[] {
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer;
  }

  private _pushOnDataLayer(obj: object): void {
    const dataLayer = this._getDataLayer();
    dataLayer.push(obj);
  }
}
