import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { DateHelperService } from '@rp/services/date-helper';

@Injectable()
/* eslint-disable @typescript-eslint/no-explicit-any */
export class SubmittedTimeAndCountInterceptor implements HttpInterceptor {
  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      request.method === 'PATCH' &&
      request.url.includes('profile/v0/my-profile')
    ) {
      const url = new URL(request.url);
      const exportType = encodeURIComponent(url.searchParams.get('export'));
      // We need to make sure we're dealing with a request to submit
      if (exportType === 'true') {
        // Loop through and add the various details
        request.body.sections.forEach((section) => {
          // Set our submitted time and count
          section.values[section.id + '_tile_submitted_time'] = [
            DateHelperService.now().toISOString(),
          ];
          section.values[section.id + '_tile_submitted_count'] = [1];
        });
      }
    }

    return next.handle(request);
  }
}
