import { Inject, Injectable } from '@angular/core';
import { merge, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environment';
import { LoggingService } from '@rp/services/logging';
import { DivisionMaskService } from '@rp/services/division-mask';
import { DivisionMask, Scope, ScopeValue } from '@rp/models';

@Injectable({
  providedIn: 'root',
})
export class ScopeService {
  constructor(
    private _loggingService: LoggingService,
    private _divisionMaskService: DivisionMaskService,
    @Inject('HOST') private _host: string,
  ) {
    let value: ScopeValue = 'tns';
    if (this._host.includes(environment.scope.sngDomain)) {
      value = 'sng';
    }
    this.emit(value);
  }

  private _scope = new ReplaySubject<Scope>(1);
  public data$: Observable<Scope> = merge(
    this._scope,
    this._divisionMaskService.divisionMask$.pipe(
      map((division) => ({
        title: this._getScopeTitle(division),
        value: this._getScopeValue(division),
      })),
    ),
  );

  public emit(data: ScopeValue): void {
    const obj: Scope = {
      title: this._getScopeTitle(data),
      value: this._getScopeValue(data),
    };
    this._loggingService.log('scopeService', obj);
    this._scope.next(obj);
  }

  private _getScopeTitle(value: ScopeValue | DivisionMask): string {
    return value.includes('sng')
      ? 'The Scottish Nursing Guild'
      : 'Thornbury Nursing';
  }

  private _getScopeValue(value: ScopeValue | DivisionMask): ScopeValue {
    switch (value) {
      case 'sngni':
        return 'sng';
      default:
        return value;
    }
  }
}
