import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '@rp/services/storage';
import { AuthService } from '@rp/services/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard implements CanActivate {
  constructor(
    private _authService: AuthService,
    private _storageService: StorageService,
  ) {}

  public canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this._authService.isAuthenticated()) {
      this._storageService.remove('accessToken');
      this._storageService.remove('expiresAt');
      this._storageService.remove('division_mask');
    }
    return true;
  }
}
