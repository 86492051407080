import { Component, Input } from '@angular/core';

@Component({
  selector: 'svg-icon',
  templateUrl: './svg-icon.component.html',
  standalone: true,
})
export class SvgIconComponent {
  @Input() public id: string;
  @Input() public className: string;
}
