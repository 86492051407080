import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  constructor(private _updates: SwUpdate) {
    if (_updates.isEnabled) {
      interval(6 * 60 * 60).subscribe(() =>
        _updates
          .checkForUpdate()
          .then(() => console.log('checking for updates')),
      );
    }
  }

  public checkForUpdates(): void {
    this._updates.available.subscribe(() => this._promptUser());
  }

  private _promptUser(): void {
    console.log('updating to new version');
    this._updates.activateUpdate().then(() => document.location.reload());
  }
}
