import { delay, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { environment } from 'environment';
import { from, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Dayjs, ManipulateType, OpUnitType } from 'dayjs';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { DateHelperService } from '@rp/services/date-helper';
import {
  ApplicationForm,
  ApplicationFormValues,
  HigherEducation,
  HistoryRecord,
  LeaveOfAbsence,
  OptionRevealed,
  SelectOption,
  WorkHistory,
} from '@rp/models';
import { ProfileFormService } from '@rp/services/profile-form';

/*eslint-disable @typescript-eslint/no-explicit-any*/
@Injectable()
export class ApplicationFormHelperService {
  public static sectionsLabels = {
    'personal-details': 'Personal details',
    'skills-and-qualifications': 'Skills and qualifications',
    'employment-history': '3-year employment history',
  };

  public static sectionsOrder: string[] = [
    'personal-details',
    'skills-and-qualifications',
    'employment-history',
  ];

  constructor() {}

  public static generatePinInputs(skillsSection: any): {
    [key: string]: any | Array<any>;
  } {
    const { hcpc_pin, nmbi_pin, nmc_pin, sssc_niscc_number } =
      skillsSection['qualification'].controls;
    return {
      ...(hcpc_pin['visible'] ? { hcpc_pin: hcpc_pin['value'] } : {}),
      ...(sssc_niscc_number['visible']
        ? { sssc_niscc_number: sssc_niscc_number['value'] }
        : {}),
      ...(nmbi_pin['visible'] ? { nmbi_pin: nmbi_pin['value'] } : {}),
      ...(nmc_pin['visible'] ? { nmc_pin: nmc_pin['value'] } : {}),
    };
  }

  public static generateAddressInputs(personalDetailsSection: any): {
    [key: string]: any | Array<any>;
  } {
    return {
      postcode: personalDetailsSection['address'].controls['postcode']?.value,
      county: personalDetailsSection['address'].controls['county']?.value,
      town: personalDetailsSection['address'].controls['town']?.value,
      address_line_1:
        personalDetailsSection['address'].controls['address_line_1']?.value,
      address_line_2:
        personalDetailsSection['address'].controls['address_line_2']?.value,
    };
  }

  public static generateHistoryInputs(employmentHistorySection: {
    history: HistoryRecord[];
  }): { [key: string]: any | Array<any> } {
    return employmentHistorySection.history.reduce(
      (acc: any, current: HistoryRecord) => {
        if (current.data.type === 'work_history') {
          acc[current.data.type].push({
            date_from_work_history: DateHelperService.toDayjs(
              current.data.dateFrom,
            ).format('MM/YYYY'),
            date_to_work_history: DateHelperService.toDayjs(
              current.data.dateTo,
            ).format('MM/YYYY'),
            position: current.data.position,
            ward_type: current.data.ward_type,
            ward_name: current.data.ward_name,
            employer_hospital_name: current.data.employer_hospital_name,
            currently_work_here: current.data.currentlyHere,
            work_history_status: '',
            work_history_comment: '',
          });
        } else if (current.data.type === 'higher_education') {
          acc[current.data.type].push({
            date_from_education: DateHelperService.toDayjs(
              current.data.dateFrom,
            ).format('MM/YYYY'),
            date_to_education: DateHelperService.toDayjs(
              current.data.dateTo,
            ).format('MM/YYYY'),
            university_name: current.data.university_name,
            course: current.data.course,
            currently_study_here: current.data.currentlyHere,
            higher_education_status: '',
            higher_education_comment: '',
          });
        } else if (current.data.type === 'leave_of_absence') {
          acc[current.data.type].push({
            date_from_absence: DateHelperService.toDayjs(
              current.data.dateFrom,
            ).format('MM/YYYY'),
            date_to_absence: DateHelperService.toDayjs(
              current.data.dateTo,
            ).format('MM/YYYY'),
            absence_reasons: current.data.absence_reasons,
            absence_status: '',
            absence_comment: '',
          });
        }
        return acc;
      },
      {
        work_history: [],
        leave_of_absence: [],
        higher_education: [],
      },
    );
  }

  /**
   * Return a FormGroup for work history
   */
  public static buildWorkHistoryGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      date_from_work_history: new UntypedFormControl('', [
        Validators.required,
        ApplicationFormHelperService.validDayjsDate('MM/YYYY'),
        ApplicationFormHelperService.validRange('MM/YYYY'),
      ]),
      date_to_work_history: new UntypedFormControl('', [
        Validators.required,
        ApplicationFormHelperService.validDayjsDate('MM/YYYY'),
        ApplicationFormHelperService.validRange('MM/YYYY'),
      ]),
      position: new UntypedFormControl('', [
        Validators.maxLength(30),
        Validators.required,
      ]),
      ward_type: new UntypedFormControl('', [
        Validators.maxLength(49),
        Validators.required,
      ]),
      ward_name: new UntypedFormControl('', [
        Validators.maxLength(49),
        Validators.required,
      ]),
      employer_hospital_name: new UntypedFormControl('', [
        Validators.maxLength(100),
        Validators.required,
      ]),
      currently_work_here: new UntypedFormControl(false),
    });
  }

  /**
   * Valid date not in the future or in the past
   */
  public static validCurrentDate(format: string, future: boolean): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      const controlValue = DateHelperService.toDayjs(control.value, format);
      const valid = future
        ? DateHelperService.isFutureDate(controlValue)
        : DateHelperService.isPastDate(controlValue);
      return valid
        ? {
            message:
              'Please enter a valid date ' +
              (future ? 'before' : 'after') +
              ' the current date',
          }
        : null;
    };
  }

  /**
   * Provide a date range to validate against.
   * @param {string} format - Format to transform date to.
   * @param {Dayjs} [endDate=DateHelperService.now()] - End of the date range.
   * @param {Dayjs} [startDate=DateHelperService.today().subtract(129, 'years')] - Beginning of the date range.
   * @param {string} [customErrorMessage=null] - Custom Error Message. Use $1 for startDate, or $2 for endDate.
   * @param {string} [rangeCheckStartType='inclusive'] - Exclusive or Inclusive range check for start date.
   * @param {string} [rangeCheckEndType='inclusive'] - Exclusive or Inclusive range check for end date.
   * */
  public static validRange(
    format: string,
    endDate: Dayjs = DateHelperService.now(),
    startDate: Dayjs = DateHelperService.today().subtract(129, 'years'),
    customErrorMessage: string = null,
    rangeCheckStartType: 'exclusive' | 'inclusive' = 'inclusive',
    rangeCheckEndType: 'exclusive' | 'inclusive' = 'inclusive',
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const unitTypeToUse: OpUnitType =
        format.toLowerCase().indexOf('d') !== -1 ? 'day' : 'month';
      const dayjsDate =
        control.value instanceof Date
          ? DateHelperService.toDayjs(control.value)
          : control.value;
      const controlDate = DateHelperService.toDayjs(dayjsDate, format);
      const validStartDate = DateHelperService.toDayjs(
        startDate.startOf(unitTypeToUse),
        format,
      );
      const validEndDate = DateHelperService.toDayjs(
        endDate.endOf(unitTypeToUse),
        format,
      );
      const valid = DateHelperService.isBetween(
        controlDate,
        validStartDate,
        validEndDate,
        rangeCheckStartType,
        rangeCheckEndType,
      );

      if (!valid) {
        const formatDateForErrorMessage = (
          date: Dayjs,
          startOrEndDate: boolean,
          rangeCheckType: 'exclusive' | 'inclusive',
        ): string => {
          if (rangeCheckType === 'exclusive') {
            /*If exclusive, we need to be consistent with the error messages and display a correct start and end date.
              Therefore, we add/subtract a day or month of the date.
              For example, I want an exclusive date between 12-Feb-2020 and 15-Feb-2020, the only two valid days are the
              13th and 14th. So we set the error message to say 'Please enter a valid date between 13-Feb-2020 and 14-Feb-2020'.
            */
            return startOrEndDate
              ? date.add(1, unitTypeToUse).format(format)
              : date.subtract(1, unitTypeToUse).format(format);
          }
          return date.format(format);
        };
        const errorMessageStartDate = formatDateForErrorMessage(
          startDate,
          true,
          rangeCheckStartType,
        );
        const errorMessageEndDate = formatDateForErrorMessage(
          endDate,
          false,
          rangeCheckEndType,
        );
        const message = customErrorMessage
          ? customErrorMessage
              .replace('$1', errorMessageStartDate)
              .replace('$2', errorMessageEndDate)
          : 'Please enter a valid date between ' +
            errorMessageStartDate +
            ' and ' +
            errorMessageEndDate;
        return { message };
      }

      return null;
    };
  }

  public static validFutureDate(
    limit: number,
    unit: ManipulateType,
    format: string,
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const startDate = DateHelperService.now().startOf('day'),
        endDate = startDate.clone().add(limit, unit).endOf('day'),
        controlValue = DateHelperService.toDayjs(control.value, format),
        valid =
          controlValue.isSameOrAfter(startDate) &&
          controlValue.isSameOrBefore(endDate);
      return valid
        ? null
        : {
            message:
              'Please enter a valid date between ' +
              startDate.format(format) +
              ' and ' +
              endDate.format(format),
          };
    };
  }

  /**
   * Valid date format
   */
  public static validDayjsDate(format: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = DateHelperService.toDayjs(control.value, format).isValid();
      return valid
        ? null
        : { dayjsDate: 'Please enter a valid date in ' + format + ' format' };
    };
  }

  public static validNMCPin(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = /^[0-9][0-9][A-Za-z][0-9][0-9][0-9][0-9][A-Za-z]$/.test(
        control.value,
      );
      return valid ? null : { message: 'Please enter a valid NMC Pin' };
    };
  }

  public static validSortCode(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid: boolean = /^[0-9]{6}$/.test(control.value);
      return valid ? null : { message: 'Please enter a valid Sort Code' };
    };
  }

  public static validAccountNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid: boolean = /^[0-9]{8}$/.test(control.value);
      return valid ? null : { message: 'Please enter a valid Account Number' };
    };
  }

  /**
   * Valid name, only alpha and hyphens
   */
  public static validName(type: 'firstname' | 'surname'): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = /^([A-Za-z]+(?:[ -][A-Za-z]+)*)?$/.test(control.value);
      return valid
        ? null
        : {
            message: `Please enter your ${type === 'surname' ? 'Last name' : 'First name'} (letters & hyphens only)`,
          };
    };
  }

  /**
   * Valid full name, only alpha and hyphens and spaces
   */
  public static validFullName(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = /([-A-Za-z0].+\s).+/.test(control.value);
      return valid
        ? null
        : {
            message:
              'Please enter your Full Name (letters, spaces & hyphens only)',
          };
    };
  }

  public static validRightToWork(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = control.value !== 'no';
      return valid
        ? null
        : {
            message:
              'Unfortunately you require the right to work in the UK to continue with your application.',
          };
    };
  }

  /**
   * Valid only alphanumeric
   */

  public static validAlphanumeric(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = /^[a-z0-9]+$/i.test(control.value);
      return valid
        ? null
        : { message: 'Please enter alphanumeric characters only.' };
    };
  }

  /**
   * Date To cannot be before Date From
   */
  public static validWorkHistoryRange(
    format: string,
    granularity: OpUnitType = 'month',
  ): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: any } => {
      if (control.parent) {
        if (!control.value) {
          return null;
        }
        const group: UntypedFormGroup | UntypedFormArray = control.parent;
        const start: AbstractControl = group.get(
          Object.keys(group.controls).find(
            (key) => key.toLowerCase().indexOf('from') > -1,
          ),
        );
        const end: AbstractControl = group.get(
          Object.keys(group.controls).find(
            (key) => key.toLowerCase().indexOf('to') > -1,
          ),
        );

        const valid = (): { workHistoryRange: string } | null => {
          if (start.value !== null && end.value !== null) {
            if (
              DateHelperService.toDayjs(end.value, format).isBefore(
                DateHelperService.toDayjs(start.value, format),
                granularity,
              )
            ) {
              return {
                workHistoryRange:
                  'Your Date To is before your Date From. Please correct.',
              };
            } else if (
              DateHelperService.toDayjs(start.value, format).isAfter(
                DateHelperService.toDayjs(end.value, format),
                granularity,
              )
            ) {
              return {
                workHistoryRange:
                  'Date From is after your Date To. Please correct.',
              };
            } else {
              if (start.errors && start.errors.workHistoryRange) {
                start.setErrors({ workHistoryRange: null });
                start.updateValueAndValidity();
              } else if (end.errors && end.errors.workHistoryRange) {
                end.setErrors({ workHistoryRange: null });
                end.updateValueAndValidity();
              }
              return null;
            }
          } else {
            return null;
          }
        };

        return valid();
      }
    };
  }

  /**
   * Valid 11 digit number
   */
  public static validTelephoneNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = /^[0-9]*$/.test(control.value);
      return valid ? null : { message: 'Please enter a valid number' };
    };
  }

  public static validPhoneNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = parsePhoneNumber(control.value).valid;
      return valid ? null : { message: 'Please enter a valid number' };
    };
  }

  /**
   * Asynchronous validator to check if email address exists
   * @param value
   */
  public static isEmailUnique(value: string = ''): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> | null => {
      if (!control.value) {
        return of(null);
      }
      if (
        ApplicationFormHelperService.validEmailAddress.bind(control.value) &&
        value !== control.value
      ) {
        return of(control.value).pipe(
          tap((email) => (value = email)),
          delay(500),
          distinctUntilChanged(),
          switchMap(() =>
            from(
              fetch(
                `${environment.apiEndpoint}/tns-layer/profile/v0/available-email`,
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({ email: control.value }),
                },
              ).then((r: Response) =>
                r.status === 200
                  ? null
                  : {
                      emailAlreadyInUse:
                        'An account with this email already exists',
                    },
              ),
            ),
          ),
        );
      } else {
        return of(null);
      }
    };
  }

  /**
   * Max length validator with custom error message.
   * @param maxLength
   */
  public static clinicalExperienceMaxLength(maxLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      if (Validators.maxLength(maxLength)(control)) {
        return {
          message: `We only require ${maxLength} relevant and applicable clinical experiences`,
        };
      }
      return null;
    };
  }

  /**
   * Input values must match
   * @param confirmInput
   */
  public static confirmField(confirmInput: string): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.parent) {
        return null;
      }

      const confirm = (value: string): boolean => value === control.value;
      const init: AbstractControl = control.parent.get(
        confirmInput,
      ) as UntypedFormControl;
      if (init) {
        init.valueChanges
          .pipe(distinctUntilChanged())
          .subscribe((value: string) => {
            if (confirm(value) && control.errors) {
              control.setErrors({ confirm: null });
              control.updateValueAndValidity();
            } else if (!confirm(value) && !control.errors) {
              control.setErrors({
                confirm: `Your ${confirmInput} does not match the above`,
              });
              control.updateValueAndValidity();
            }
          });
      }

      return confirm(init.value)
        ? null
        : { confirm: `Your ${confirmInput} does not match the above` };
    };
  }

  /**
   * Valid email address format
   */
  public static validEmailAddress(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      // eslint-disable-next-line max-len
      const valid = /^([-a-zA-Z0-9.+_]+)@((?:[a-z0-9-]+\.)+[a-z]{2,})$/i.test(
        control.value,
      );
      return valid ? null : { message: 'Please enter a valid email address' };
    };
  }

  /**
   * Valid email address format
   */
  public static noWhiteSpace(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      return (control.value as string).indexOf(' ') > -1
        ? { message: 'Please remove spaces.' }
        : null;
    };
  }

  /**
   * Valid non empty strings
   */
  public static validNonEmptyString(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      return !control.value.trim()
        ? { message: 'This is a required field.' }
        : null;
    };
  }

  /**
   * Valid Clinical Experiences
   */
  public static validClinicalExperience(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      return (control.value as string[]).length
        ? null
        : { message: 'Please add your clinical experience' };
    };
  }

  /**
   * Valid Specialisms
   */
  public static validSpecialisms(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      return (control.value as string[]).length
        ? null
        : { message: 'Please add your specialisms' };
    };
  }

  /**
   ^                         Start anchor
   (?=.*[A-Z].*[A-Z])        Ensure string has one uppercase letters.
   (?=.*[!@#$&*#])            Ensure string has one special case letter.
   (?=.*[0-9].*[0-9])        Ensure string has one digits.
   (?=.*[a-z].*[a-z].*[a-z]) Ensure string has three lowercase letters.
   .{8}                      Ensure string is of minimum length 8.
   $                         End anchor.
   */
  public static validPassword(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@£$^&*#])[A-Za-z\d!£^@$&*#]{6,}$/.test(
          control.value,
        );
      return valid ? null : { message: 'Your password is not valid.' };
    };
  }

  /**
   * Custom validator for WorkHistory
   * We must have 36 month service unbroken
   * @param control
   */
  public static validWorkHistory(control: UntypedFormControl): {
    message: string;
  } {
    const currentDate = DateHelperService.now().format('YYYY-MM-DD');
    const cutOff = DateHelperService.now()
      .subtract(3, 'years')
      .format('YYYY-MM-DD');
    const range = ApplicationFormHelperService.createRange(
      cutOff,
      currentDate,
      'months',
    );

    let workHistoryRange = [];

    control.value.forEach((date) => {
      const startDate = DateHelperService.toDayjs(
        `01/${date.date_from_work_history}`,
      ).format('DD/MM/YYYY');
      const endDate = DateHelperService.toDayjs(
        `01/${date.date_to_work_history}`,
      ).format('DD/MM/YYYY');
      workHistoryRange.push(
        ApplicationFormHelperService.createRange(startDate, endDate, 'months'),
      );
    });

    workHistoryRange = [].concat.apply([], workHistoryRange);

    workHistoryRange.forEach((item) => {
      const workItem = range.filter((obj) => obj === item)[0];
      for (let i = 0; i < range.length; i++) {
        if (range[i] === workItem) {
          range.splice(i, 1);
        }
      }
    });

    return range.length
      ? {
          // eslint-disable-next-line max-len
          message: `We're missing some information. We need your full work history from the last 3 years (36 months). Unfortunately, you've only provided ${36 - range.length} months. Please review before continuing.`,
        }
      : null;
  }

  /**
   ^                 Start anchor
   \s*               optional leading whitespace
   [a-zA-Z]{2}       match two letters
   (?:\s*\d\s*){6}   six digits, with optional whitespace leading/trailing
   [a-zA-Z]?         zero or one letter
   \s*               optional trailing whitespace (just in case)
   $                 End anchor.
   */
  public static validNiNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = /^\s*[a-zA-Z]{2}(?:\s*\d\s*){6}[a-zA-Z]?\s*$/.test(
        control.value,
      );
      return valid ? null : { message: 'Please enter a valid NI Number' };
    };
  }

  /**
   * Return options for Qualification FormControl
   * @param scope
   */
  public static qualificationOptions(scope): SelectOption[] {
    const options = [
      { id: 'anp', label: 'ANP' },
      { id: 'registered_midwife', label: 'Registered Midwife' },
      { id: 'registered_nurse', label: 'Registered Nurse' },
      { id: 'student_nurse', label: 'Student Nurse' },
      { id: 'healthcare_assistant', label: 'Healthcare Assistant' },
    ];

    if (['tns', 'sng'].indexOf(scope) > -1) {
      options.splice(1, 0, { id: 'enp', label: 'ENP' });
      options.splice(2, 0, { id: 'odp', label: 'ODP' });
      options.splice(3, 0, { id: 'ecp', label: 'ECP' });
    }

    // Arrange the options into alphabetical order
    options.sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });

    return options;
  }

  public static specialismOptions(
    type: string,
    scope?: string,
  ): SelectOption[] {
    if (type === 'registered_nurse') {
      if (scope === 'sngroi') {
        return [
          { id: 'general_nurse', label: 'Registered General Nurse' },
          { id: 'childrens_nurse', label: 'Registered Sick Children’s Nurse' },
          {
            id: 'mental_health_nurse',
            label: 'Registered Mental Health Nurse',
          },
          {
            id: 'registered_nurse_intellectual_disabilities',
            label: 'Registered Nurse Intellectual Disabilities',
          },
        ];
      } else {
        return [
          { id: 'general_nurse', label: 'Registered General Nurse' },
          { id: 'childrens_nurse', label: 'Registered Sick Children’s Nurse' },
          {
            id: 'mental_health_nurse',
            label: 'Registered Mental Health Nurse',
          },
          {
            id: 'learning_disabilities_nurse',
            label: 'Registered Nurse Learning Disabilities',
          },
        ];
      }
    } else if (type === 'student_nurse') {
      return [
        { id: 'general_specialism', label: 'General' },
        { id: 'mental_health_specialism', label: 'Mental Health' },
        {
          id: 'learning_disabilities_specialism',
          label: 'Learning Disabilities',
        },
      ];
    } else if (type === 'healthcare_assistant') {
      if (['sng', 'sngni'].indexOf(scope) > -1) {
        return [
          { id: 'general_specialism', label: 'General' },
          { id: 'mental_health_specialism', label: 'Mental Health' },
          {
            id: 'learning_disabilities_specialism',
            label: 'Learning Disabilities',
          },
          { id: 'community', label: 'Community' },
        ];
      } else {
        return [
          { id: 'general_specialism', label: 'General' },
          { id: 'mental_health_specialism', label: 'Mental Health' },
          {
            id: 'learning_disabilities_specialism',
            label: 'Learning Disabilities',
          },
        ];
      }
    }
  }

  /**
   * Return options for Clinical Experience FormControl
   */
  public static clinicalExpOptions(): OptionRevealed[] {
    return [
      {
        id: 'ane',
        label: 'A&E',
        qualification: [
          'anp',
          'enp',
          'ecp',
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: ['general_nurse', 'childrens_nurse', 'general_specialism'],
      },
      {
        id: 'ccu',
        label: 'CCU',
        qualification: [
          'anp',
          'enp',
          'ecp',
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: ['general_nurse', 'childrens_nurse', 'general_specialism'],
      },
      {
        id: 'chemotherapy',
        label: 'Chemotherapy',
        qualification: [
          'anp',
          'enp',
          'ecp',
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: ['general_nurse', 'childrens_nurse', 'general_specialism'],
      },
      {
        id: 'community',
        label: 'Community',
        qualification: [
          'anp',
          'enp',
          'ecp',
          'registered_midwife',
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: [
          'general_nurse',
          'mental_health_nurse',
          'learning_disabilities_nurse',
          'childrens_nurse',
          'mental_health_specialism',
          'general_specialism',
          'learning_disabilities_specialism',
          'community',
          'registered_nurse_intellectual_disabilities',
        ],
      },
      {
        id: 'community_complex_care_adult',
        label: 'Community Complex Care',
        qualification: ['registered_nurse'],
        specialism: ['general_nurse', 'childrens_nurse'],
      },
      {
        id: 'circulating',
        label: 'Circulating',
        qualification: ['odp'],
        specialism: [],
      },
      {
        id: 'district_nurse',
        label: 'District Nurse',
        qualification: ['anp', 'enp', 'ecp', 'registered_nurse'],
        specialism: ['general_nurse'],
      },
      {
        id: 'hdu',
        label: 'HDU',
        qualification: [
          'anp',
          'enp',
          'ecp',
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: ['general_nurse', 'childrens_nurse', 'general_specialism'],
      },
      {
        id: 'itu',
        label: 'ITU',
        qualification: [
          'anp',
          'enp',
          'ecp',
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: ['general_nurse', 'childrens_nurse', 'general_specialism'],
      },
      {
        id: 'learning_disabilities',
        label: 'Learning Disabilities',
        qualification: [
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: [
          'learning_disabilities_nurse',
          'mental_health_specialism',
          'registered_nurse_intellectual_disabilities',
        ],
      },
      {
        id: 'nicu',
        label: 'NICU',
        qualification: [
          'anp',
          'enp',
          'ecp',
          'registered_midwife',
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: ['general_nurse', 'childrens_nurse', 'general_specialism'],
      },
      {
        id: 'scbu',
        label: 'SCBU',
        qualification: [
          'anp',
          'enp',
          'ecp',
          'registered_midwife',
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: ['general_nurse', 'childrens_nurse', 'general_specialism'],
      },
      {
        id: 'nursing_home',
        label: 'Nursing Home',
        qualification: [
          'anp',
          'enp',
          'ecp',
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: ['general_specialism', 'general_nurse'],
      },
      {
        id: 'nursing_care_home',
        label: 'Nursing/Care Home',
        qualification: [
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: [
          'mental_health_nurse',
          'mental_health_specialism',
          'learning_disabilities_specialism',
          'learning_disabilities_nurse',
          'registered_nurse_intellectual_disabilities',
        ],
      },
      {
        id: 'paediatrics',
        label: 'Paediatrics',
        qualification: ['anp', 'enp', 'ecp', 'registered_nurse'],
        specialism: ['general_nurse', 'general_specialism'],
      },
      {
        id: 'practice_nurse',
        label: 'Practice Nurse',
        qualification: ['anp', 'enp', 'ecp', 'registered_nurse'],
        specialism: ['general_nurse'],
      },
      {
        id: 'prisons',
        label: 'Prisons',
        qualification: [
          'anp',
          'enp',
          'ecp',
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: ['general_nurse', 'general_specialism'],
      },
      {
        id: 'renal_dialysis',
        label: 'Renal Dialysis',
        qualification: [
          'anp',
          'enp',
          'ecp',
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: ['general_nurse', 'childrens_nurse', 'general_specialism'],
      },
      {
        id: 'schools',
        label: 'School Nurse',
        qualification: [
          'anp',
          'enp',
          'ecp',
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: ['general_nurse', 'childrens_nurse', 'general_specialism'],
      },
      {
        id: 'theatres',
        label: 'Theatres',
        qualification: ['anp', 'enp', 'ecp'],
        specialism: [],
      },
      {
        id: 'wards',
        label: 'Wards',
        qualification: [
          'anp',
          'enp',
          'ecp',
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: ['general_nurse', 'childrens_nurse', 'general_specialism'],
      },
      {
        id: 'other',
        label: 'Other',
        qualification: [
          'anp',
          'enp',
          'ecp',
          'odp',
          'registered_midwife',
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: [
          'general_nurse',
          'mental_health_nurse',
          'learning_disabilities_nurse',
          'childrens_nurse',
          'mental_health_specialism',
          'general_specialism',
          'learning_disabilities_specialism',
          'community',
          'registered_nurse_intellectual_disabilities',
        ],
      },
      {
        id: 'endoscopy',
        label: 'Endoscopy',
        qualification: ['anp', 'enp', 'ecp', 'odp'],
        specialism: [],
      },
      {
        id: 'minor_injuries',
        qualification: ['anp', 'enp', 'ecp'],
        specialism: [],
        label: 'Minor Injuries',
      },
      {
        id: 'minor_illness',
        qualification: ['anp', 'enp', 'ecp'],
        specialism: [],
        label: 'Minor Illness',
      },
      {
        id: 'antenatal',
        label: 'Antenatal',
        qualification: ['registered_midwife'],
        specialism: [],
      },
      {
        id: 'intranatal',
        label: 'Intranatal',
        qualification: ['registered_midwife'],
        specialism: [],
      },
      {
        id: 'postnatal',
        label: 'Postnatal',
        qualification: ['registered_midwife'],
        specialism: [],
      },
      {
        id: 'delivery_suite',
        label: 'Delivery Suite',
        qualification: ['registered_midwife'],
        specialism: [],
      },
      {
        id: 'birthing_centre',
        label: 'Birthing Centre',
        qualification: ['registered_midwife'],
        specialism: [],
      },
      {
        id: 'theatre_anesthetics',
        label: 'Theatres - Anesthetics',
        qualification: [
          'odp',
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: ['general_nurse', 'general_specialism'],
      },
      {
        id: 'theatres_other',
        label: 'Theatres - Other',
        qualification: [
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: ['general_nurse', 'general_specialism'],
      },
      {
        id: 'theatres_circulating',
        label: 'Theatres - Circulating',
        qualification: [
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: ['general_nurse', 'general_specialism'],
      },
      {
        id: 'theatre_scrub',
        label: 'Theatres - Scrub',
        qualification: [
          'odp',
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: ['general_nurse', 'general_specialism'],
      },
      {
        id: 'theatre_recovery',
        label: 'Theatres - Recovery',
        qualification: [
          'odp',
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: ['general_nurse', 'general_specialism'],
      },
      {
        id: 'surgical_first_assistant',
        label: 'Theatres - 1st Assistant',
        qualification: ['registered_nurse', 'student_nurse'],
        specialism: ['general_nurse'],
      },
      {
        id: 'theatre_endoscopy',
        label: 'Theatres - Endoscopy',
        qualification: [
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: ['general_nurse', 'general_specialism'],
      },
      {
        id: 'acute',
        label: 'Acute',
        qualification: [
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: [
          'mental_health_nurse',
          'learning_disabilities_nurse',
          'mental_health_specialism',
          'learning_disabilities_specialism',
          'registered_nurse_intellectual_disabilities',
        ],
      },
      {
        id: 'camhs',
        label: 'CAMHS',
        qualification: [
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: [
          'mental_health_nurse',
          'learning_disabilities_nurse',
          'mental_health_specialism',
          'learning_disabilities_specialism',
          'registered_nurse_intellectual_disabilities',
        ],
      },
      {
        id: 'drugs_alcohol',
        label: 'Drug & Alcohol',
        qualification: [
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: [
          'mental_health_nurse',
          'learning_disabilities_nurse',
          'mental_health_specialism',
          'learning_disabilities_specialism',
          'registered_nurse_intellectual_disabilities',
        ],
      },
      {
        id: 'eating_disorders',
        label: 'Eating disorders',
        qualification: [
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: [
          'mental_health_nurse',
          'learning_disabilities_nurse',
          'mental_health_specialism',
          'learning_disabilities_specialism',
          'registered_nurse_intellectual_disabilities',
        ],
      },
      {
        id: 'recovery_rehabilitation',
        label: 'Recovery and Rehabilitation',
        qualification: [
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: [
          'mental_health_nurse',
          'learning_disabilities_nurse',
          'mental_health_specialism',
          'learning_disabilities_specialism',
          'registered_nurse_intellectual_disabilities',
        ],
      },
      {
        id: 'psychiatric_intensive_care',
        label: 'Psychiatric Intensive Care',
        qualification: [
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: [
          'mental_health_nurse',
          'learning_disabilities_nurse',
          'mental_health_specialism',
          'learning_disabilities_specialism',
          'registered_nurse_intellectual_disabilities',
        ],
      },
      {
        id: 'forensic',
        label: 'Forensic',
        qualification: [
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: [
          'mental_health_nurse',
          'learning_disabilities_nurse',
          'mental_health_specialism',
          'learning_disabilities_specialism',
          'registered_nurse_intellectual_disabilities',
        ],
      },
      {
        id: 'dementia_assessment',
        label: 'Dementia Assessment',
        qualification: [
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: [
          'mental_health_nurse',
          'learning_disabilities_nurse',
          'mental_health_specialism',
          'learning_disabilities_specialism',
          'registered_nurse_intellectual_disabilities',
        ],
      },
      {
        id: 'crisis_team',
        label: 'Crisis Team',
        qualification: [
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: [
          'mental_health_nurse',
          'learning_disabilities_nurse',
          'mental_health_specialism',
          'learning_disabilities_specialism',
          'community',
          'registered_nurse_intellectual_disabilities',
        ],
      },
      {
        id: 'psychiatric_liaison',
        label: 'Psychiatric Liaison',
        qualification: ['registered_nurse'],
        specialism: [
          'mental_health_nurse',
          'learning_disabilities_nurse',
          'registered_nurse_intellectual_disabilities',
        ],
      },
      {
        id: 'learning_disability_admissions',
        label: 'Learning Disability Admissions',
        qualification: [
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: [
          'mental_health_nurse',
          'learning_disabilities_nurse',
          'mental_health_specialism',
          'learning_disabilities_specialism',
          'registered_nurse_intellectual_disabilities',
        ],
      },
      {
        id: 'learning_disability_forensic',
        label: 'Learning Disability Forensic and Secure Units',
        qualification: [
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: [
          'mental_health_nurse',
          'learning_disabilities_nurse',
          'mental_health_specialism',
          'learning_disabilities_specialism',
          'registered_nurse_intellectual_disabilities',
        ],
      },
      {
        id: 'learning_disability_cahms',
        label: 'Learning Disability Cahms',
        qualification: [
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: [
          'mental_health_nurse',
          'learning_disabilities_nurse',
          'mental_health_specialism',
          'learning_disabilities_specialism',
          'registered_nurse_intellectual_disabilities',
        ],
      },
      {
        id: 'learning_disability_older',
        label: 'Learning Disability Older Adult',
        qualification: [
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: [
          'mental_health_nurse',
          'learning_disabilities_nurse',
          'mental_health_specialism',
          'learning_disabilities_specialism',
          'registered_nurse_intellectual_disabilities',
        ],
      },
      {
        id: 'learning_disability_intensive_care_unit',
        label: 'Learning Disability Intensive Care Unit',
        qualification: [
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: [
          'mental_health_nurse',
          'learning_disabilities_nurse',
          'mental_health_specialism',
          'learning_disabilities_specialism',
          'registered_nurse_intellectual_disabilities',
        ],
      },
      {
        id: 'learning_disability_supported_living',
        label: 'Learning Disability Supported Living',
        qualification: [
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: [
          'mental_health_nurse',
          'learning_disabilities_nurse',
          'mental_health_specialism',
          'learning_disabilities_specialism',
          'registered_nurse_intellectual_disabilities',
        ],
      },
      {
        id: 'learning_disability_community',
        label: 'Learning Disability Community',
        qualification: [
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: [
          'mental_health_nurse',
          'learning_disabilities_nurse',
          'mental_health_specialism',
          'learning_disabilities_specialism',
          'community',
          'registered_nurse_intellectual_disabilities',
        ],
      },
      {
        id: 'learning_disability_units_continuing_care',
        label: 'Learning Disability Units Continuing care',
        qualification: [
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: [
          'mental_health_nurse',
          'learning_disabilities_nurse',
          'mental_health_specialism',
          'learning_disabilities_specialism',
          'registered_nurse_intellectual_disabilities',
        ],
      },
      {
        id: 'acquired_brain_injury_units',
        label: 'Acquired Brain Injury Units',
        qualification: [
          'healthcare_assistant',
          'registered_nurse',
          'student_nurse',
        ],
        specialism: [
          'mental_health_nurse',
          'learning_disabilities_nurse',
          'mental_health_specialism',
          'learning_disabilities_specialism',
          'registered_nurse_intellectual_disabilities',
        ],
      },
      {
        id: 'hca_hsw',
        label: 'HCA HSW',
        qualification: ['healthcare_assistant'],
        specialism: ['community'],
      },
    ];
  }

  /**
   * Return options for Heard About us FormControl
   */
  public static heardAboutUsOptions(scope): SelectOption[] {
    let title = 'Thornbury Nursing Services';
    if (scope !== 'tns') {
      title = 'The Guild';
    }
    return [
      { id: 'EventOrConference', label: 'Event or conference' },
      { id: 'Website', label: `${title} website` },
      { id: 'JobBoard', label: 'Job Board' },
      { id: 'OnlineAdvert', label: 'Online advert' },
      { id: 'SocialMedia', label: 'Social media – Facebook, LinkedIn, etc' },
      { id: 'Email', label: 'Email' },
      { id: 'PrintAdvert', label: 'Print advert' },
      { id: 'ReferredByAFriend', label: 'Referred by a friend' },
      { id: 'Other', label: 'Other' },
      { id: 'PodcastAdvert', label: 'Podcast advert' },
    ];
  }

  public static leaveReasonOptions(): SelectOption[] {
    return [
      {
        id: 'maternity_paternity',
        label: 'Maternity/Paternity',
      },
      {
        id: 'health',
        label: 'Health',
      },
      {
        id: 'career_break',
        label: 'Career Break',
      },
      {
        id: 'sabbatical',
        label: 'Sabbatical',
      },
      {
        id: 'travelling',
        label: 'Travelling',
      },
      {
        id: 'moving_locations',
        label: 'Moving Locations',
      },
      {
        id: 'awaiting_pin_qualification',
        label: 'Awaiting Pin/Qualification',
      },
      {
        id: 'transition_period',
        label: 'Transition period between roles',
      },
      {
        id: 'care_responsibilities',
        label: 'Care responsibilities',
      },
      {
        id: 'overseas',
        label: 'Working, studying or living overseas',
      },
      {
        id: 'apprenticeship',
        label: 'Apprenticeship',
      },
    ];
  }

  public static titleOptions(): SelectOption[] {
    return [
      { id: 'mr', label: 'Mr' },
      { id: 'mrs', label: 'Mrs' },
      { id: 'miss', label: 'Miss' },
      { id: 'dr', label: 'Dr' },
      { id: 'ms', label: 'Ms' },
    ];
  }

  public static websiteOptions(): SelectOption[] {
    return [
      { id: 'google', label: 'Google (search)' },
      { id: 'bing', label: 'Bing (search)' },
      { id: 'yahoo', label: 'Yahoo (search)' },
      { id: 'indeed', label: 'Indeed' },
      { id: 'reed', label: 'Reed' },
      { id: 'nurses_co_uk', label: 'Nurses.co.uk' },
      { id: 'facebook', label: 'Facebook' },
      { id: 'linkedin', label: 'LinkedIn' },
      { id: 'instagram', label: 'Instagram' },
      { id: 'twitter', label: 'Twitter' },
      { id: 'other', label: 'Other (please specify)' },
      { id: 'none', label: 'None (please explain why)' },
    ];
  }

  /**
   * Return the correct structure to create an application
   * @param data
   */
  public static applicationFormat(
    data: ApplicationFormValues,
  ): ApplicationForm {
    const form: ApplicationForm = {
      authentication: {
        email: data.email_address,
        password: data.password,
      },
      values: {
        email_address: [data.email_address],
        address: [
          {
            address_line_1: [data.address_line_1],
            town: [data.town],
            county: [data.county],
            postcode: [data.postcode],
          },
        ],
        dob: [
          DateHelperService.format(
            DateHelperService.toDayjs(data.dob, 'DD/MM/YYYY'),
            'YYYY-MM-DD',
          ),
        ],
        title: [data.title],
        first_name: [data.first_name],
        surname: [data.surname],
        mobile_number: [data.mobile_number],
        landline_number: [data.landline_number],
        registered_before: [data.registered_before],
        right_to_work: [data.right_to_work],
        qualification: [data.qualification],
        clinical_experience: data.clinical_experience,
        work_history: ApplicationFormHelperService.convertToPMCStruct(
          ApplicationFormHelperService.configureArray<WorkHistory>(
            data.work_history,
          ),
        ),
        higher_education: ApplicationFormHelperService.convertToPMCStruct(
          ApplicationFormHelperService.configureArray<HigherEducation>(
            data.higher_education,
          ),
        ),
        leave_of_absence: ApplicationFormHelperService.convertToPMCStruct(
          ApplicationFormHelperService.configureArray<LeaveOfAbsence>(
            data.leave_of_absence,
          ),
        ),
        query_params: [data.query_params],
        company: [data.scope],
      },
    };

    if (data.known_as) {
      form.values['known_as'] = [data.known_as];
    }

    if (data.previous_id_number) {
      form.values['previous_id_number'] = [data.previous_id_number];
    }

    if (data.address_line_2) {
      form.values.address[0]['address_line_2'] = [data.address_line_2];
    }

    if (data.hear_about_us) {
      form.values['hear_about_us'] = [data.hear_about_us];
    }

    if (data.looking_for_shifts) {
      form.values['looking_for_shifts'] = data.looking_for_shifts;
    }

    if (data.other_reason) {
      form.values['other_reason'] = [data.other_reason];
    }

    if (data.ni_number) {
      form.values['ni_number'] = [data.ni_number];
    }

    if (data.nmc_pin) {
      form.values['nmc_pin'] = [data.nmc_pin];
    }

    if (data.sssc_niscc_number) {
      form.values['sssc_niscc_number'] = [data.sssc_niscc_number];
    }

    if (data.hcpc_pin) {
      form.values['hcpc_pin'] = [data.hcpc_pin];
    }

    if (data.previous_id_number) {
      form.values['previous_id_number'] = [data.previous_id_number];
    }

    if (data.nmbi_pin) {
      form.values['nmbi_pin'] = [data.nmbi_pin];
    }

    if (data.pps) {
      form.values['pps'] = [data.pps];
    }

    if (data.specialisms && data.specialisms.length) {
      form.values['specialisms'] = data.specialisms;
    }

    return form;
  }

  public static configureArray<T>(data: Array<T>): T[] {
    const arr: Array<T> = [];
    data.forEach((item: T) => {
      const today = DateHelperService.now().format('MM/YYYY');
      const itemKeys = Object.keys(item);
      const currentlyField = itemKeys.find(
        (k) => k.indexOf('currently') !== -1,
      );
      const dateFromField = itemKeys.find((k) => k.indexOf('date_from') !== -1);
      const dateToField = itemKeys.find((k) => k.indexOf('date_to') !== -1);

      if (item[dateToField] === today && !!currentlyField) {
        item[currentlyField] = true;
      }
      item[dateFromField] = DateHelperService.toDayjs(
        item[dateFromField],
        'MM/YYYY',
      ).format('YYYY-MM-DD');

      // We do not want to send the dateTo value if the object is the current
      if (item[currentlyField]) {
        delete item[dateToField];
      } else {
        item[dateToField] = DateHelperService.toDayjs(
          item[dateToField],
          'MM/YYYY',
        ).format('YYYY-MM-DD');
      }

      arr.push(item);
    });
    return arr;
  }

  /**
   * Convert normal ReactiveForm structure to that of PMC
   * @param data
   */
  public static convertToPMCStruct(data: Array<any>): any {
    const final = ProfileFormService.clone(data);
    Object.keys(final).forEach((i: any) => {
      Object.keys(final[i]).forEach((j) => {
        final[i][j] = [].concat([final[i][j]]);
      });
    });
    return final;
  }

  /**
   * Return an array of months in a given range of years
   * @param startDate
   * @param endDate
   * @param type
   */
  public static createRange(startDate, endDate, type): Array<string | null> {
    const fromDate = DateHelperService.toDayjs(startDate);
    const toDate = DateHelperService.toDayjs(endDate);
    const diff = toDate.diff(fromDate, type) + 1;
    const buildRange = [];
    for (let i = 0; i < diff; i++) {
      buildRange.push(
        DateHelperService.toDayjs(startDate).add(i, type).format('MM/YYYY'),
      );
    }
    return buildRange;
  }

  /**
   * Recursively mark all controls as touched and dirty and update validity
   * @param form
   */
  public static markAsTouchedAndDirty(
    form: UntypedFormGroup | UntypedFormArray,
  ): void {
    (<any>Object).keys(form.controls).forEach((key: string) => {
      const control: AbstractControl = form.controls[key];
      if (
        control instanceof UntypedFormGroup ||
        control instanceof UntypedFormArray
      ) {
        this.markAsTouchedAndDirty(
          <UntypedFormGroup | UntypedFormArray>control,
        );
      } else {
        control.markAsTouched();
        control.markAsDirty();
        control.updateValueAndValidity();
      }
    });
  }
}
