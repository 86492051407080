import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf } from '@angular/common';
import { MeterService } from '../services/meter/meter.service';

@Component({
  selector: 'app-meter',
  template: `
    <mat-progress-bar
      class="progress"
      mode="indeterminate"
      *ngIf="meter"
    ></mat-progress-bar>
  `,
  styleUrls: ['./meter.component.scss'],
  imports: [MatProgressBarModule, NgIf],
  standalone: true,
})
export class MeterComponent implements OnInit, OnDestroy {
  public meter: boolean;

  private _subscription: Subscription = new Subscription();

  constructor(
    public _meterService: MeterService,
    private _cdRef: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this._subscription = this._meterService.meter.subscribe((status) => {
      this.meter = status;
      this._cdRef.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
