import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { StorageService } from '@rp/services/storage';
import { MeterService } from '@rp/components/meter';

@Injectable()
/* eslint-disable @typescript-eslint/no-explicit-any */
export class BasicAuthInterceptor implements HttpInterceptor {
  constructor(
    private _storageService: StorageService,
    private _meter: MeterService,
  ) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    this._meter.launch(true);
    // add authorization header with basic auth credentials if available
    if (this._storageService.get('accessToken')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this._storageService.get('accessToken')}`,
        },
      });
    }

    return next.handle(request).pipe(
      finalize(() => {
        this._meter.launch(false);
      }),
    ) as any;
  }
}
