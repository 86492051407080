import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@rp/guards/auth';
import { AuthenticatedGuard } from '@rp/guards/authenticated';
import { ProfileGuard } from '@rp/guards/profile';
import { ReferencesResolver } from './features/referees/references.resolver';
import { WelcomePackGuard } from './features/welcome-pack/guards/welcome-pack.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    canActivateChild: [AuthGuard, ProfileGuard],
    children: [
      {
        path: 'covid-status',
        loadChildren: () =>
          import('./features/covid-status/covid-status.module').then(
            (m) => m.CovidStatusModule,
          ),
        canActivate: [WelcomePackGuard],
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./features/profile-sections/profile-sections.module').then(
            (m) => m.ProfileSectionsModule,
          ),
        canActivate: [WelcomePackGuard],
      },
      {
        path: 'contact-us',
        loadChildren: () =>
          import('./features/contact-us/contact-us.module').then(
            (m) => m.ContactUsModule,
          ),
      },
      {
        path: 'help-centre',
        loadChildren: () =>
          import('./features/help/help.module').then((m) => m.HelpModule),
      },
    ],
  },
  {
    path: 'welcome-pack',
    canActivate: [AuthGuard, ProfileGuard],
    loadChildren: () =>
      import('./features/welcome-pack/welcome-pack.module').then(
        (m) => m.WelcomePackModule,
      ),
  },
  {
    path: 'dashboard',
    canActivate: [WelcomePackGuard, AuthGuard],
    loadChildren: () =>
      import('./features/dashboard/dashboard.module').then(
        (m) => m.DashboardModule,
      ),
  },
  {
    path: 'application-form',
    canActivate: [AuthenticatedGuard],
    loadChildren: () =>
      import('./features/application-form/application-form.module').then(
        (m) => m.ApplicationFormModule,
      ),
  },
  {
    path: 'application-form/:scope',
    canActivate: [AuthenticatedGuard],
    loadChildren: () =>
      import('./features/application-form/application-form.module').then(
        (m) => m.ApplicationFormModule,
      ),
  },
  {
    path: 'qac',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/qac/qac.module').then((m) => m.QacModule),
  },
  {
    path: 'authentication',
    canActivate: [AuthenticatedGuard],
    loadChildren: () =>
      import('./features/authentication/authentication.module').then(
        (m) => m.AuthenticationModule,
      ),
  },
  {
    path: 'referees/:id',
    canActivate: [AuthenticatedGuard],
    loadChildren: () =>
      import('./features/referees/referees.module').then(
        (m) => m.RefereesModule,
      ),
    resolve: {
      reference: ReferencesResolver,
    },
  },
  {
    path: '**',
    redirectTo: 'authentication',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
