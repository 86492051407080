import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';
import { environment } from 'environment';
import { Scope } from '@rp/models';
import { ProfileFormService } from '@rp/services/profile-form';
import { ScopeService } from '@rp/services/scope';
import { AsyncPipe, NgIf } from '@angular/common';
import { SvgIconComponent } from '@rp/components/svg-icon-component';
import { RouterLink } from '@angular/router';
import { ButtonComponent } from '@rp/material/button-component';
import { MeterComponent } from '@rp/components/meter';
import { AuthService } from '@rp/services/auth';
import { RouterSelectors } from '@rp/store/selectors';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    SvgIconComponent,
    RouterLink,
    ButtonComponent,
    MeterComponent,
  ],
})
export class HeaderComponent implements OnInit {
  private _pagesToHideHeaderNavTo: string[] = ['referees'];
  private _pages: string[] = ['/', '/covid-status'];

  public authButtons$: Observable<boolean>;
  public installer: boolean;
  public readonly maintenance: boolean = environment.maintenance;
  public scope$: Observable<Scope>;
  public sticky: boolean;
  public hideHeaderNav = false;
  public registerLinks = environment.links;

  constructor(
    public authService: AuthService,
    public profileFormService: ProfileFormService,
    private _scopeService: ScopeService,
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    private readonly _store: Store<any>,
  ) {}

  public ngOnInit(): void {
    this.scope$ = this._scopeService.data$;
    this.stickyHeader();
    this.authButtons$ = this._store.pipe(
      select(RouterSelectors.selectRouteUrl),
      tap((url: string) => {
        this.hideHeaderNav = this._pagesToHideHeaderNavTo.some((page) =>
          url.includes(page),
        );
      }),
      map((url: string) =>
        this._pages.some((route: string) => url.indexOf(route) < 0),
      ),
    );
  }

  public stickyHeader(): void {
    window.addEventListener('scroll', () => {
      if (window.innerWidth > 970) {
        if (window.pageYOffset > 100) {
          if (!this.sticky) {
            this.sticky = true;
          }
        } else {
          if (this.sticky) {
            this.sticky = false;
          }
        }
      }
    });
  }
}
