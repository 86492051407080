import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProfileService } from '@rp/services/profile';
import { DateHelperService } from '@rp/services/date-helper';

@Injectable()
/* eslint-disable @typescript-eslint/no-explicit-any */
export class SectionStartInterceptor implements HttpInterceptor {
  constructor(private _profileService: ProfileService) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event) => {
        if (
          event instanceof HttpResponse &&
          event.url.includes('profile/v0/my-profile')
        ) {
          const url = new URL(event.url);
          const sectionId = encodeURIComponent(
            url.searchParams.get('sectionId'),
          );
          // Do we have a section that we potentially need to do anything with?
          if (sectionId && event.body) {
            const section = event.body.sections.find((o) => o.id === sectionId);
            if (
              section &&
              section.values[sectionId + '_tile_opened_time'] &&
              !section.values[sectionId + '_tile_opened_time'].length
            ) {
              // We need to send the existing values and append our timestamp, if we don't we will lose our original values
              section.values[sectionId + '_tile_opened_time'] = [
                DateHelperService.now().toISOString(),
              ];
              const values = {
                sections: [
                  {
                    id: sectionId,
                    values: section.values,
                  },
                ],
              };

              this._profileService.patch(values).subscribe();
            }
          }
        }
        return event;
      }),
    );
  }
}
