import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from 'environment';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import {
  PatchReference,
  Reference,
  SelfAssessment,
  SelfAssessmentDoc,
  SelfAssessmentFile,
} from '../../interfaces/referees.interface';
import { REFERENCE_API } from '../../constants';

@Injectable({
  providedIn: 'root',
})
export class ReferenceService {
  public skipRedirect = false;
  public reference$ = new BehaviorSubject({} as Reference);

  constructor(
    private _http: HttpClient,
    private _router: Router,
  ) {}

  public updateReference<T>(value: T): void {
    this.reference$.next({ ...this.reference$.value, ...value });
  }

  public getReference(
    guid: string,
    dbType: string,
  ): Observable<{ data: Reference[] }> {
    return this._http
      .get(
        `${environment.apiEndpoint}/${REFERENCE_API.getReference(guid, dbType)}`,
      )
      .pipe(
        tap((reference: { data: Reference[] }) =>
          this.reference$.next(
            this.mapSelfAssessmentsDocToSA({
              dbType,
              guid,
              ...reference.data[0],
            }),
          ),
        ),
        catchError(this._handleError),
      );
  }

  public getSelfAssessmentFile(
    guid: string,
    dbType: string,
    documentId: number,
  ): Observable<SelfAssessmentFile> {
    return this._http.get<SelfAssessmentFile>(
      `${environment.apiEndpoint}/${REFERENCE_API.getSelfAssessmentFile(guid, dbType, documentId)}`,
    );
  }

  public mapSelfAssessmentsDocToSA(reference: Reference): Reference {
    if (!reference.selfAssessmentsDocs || !reference.selfAssessments.length) {
      return reference;
    }
    return {
      ...reference,
      selfAssessments: reference.selfAssessments.map((selfAssessment) => {
        const docById = reference.selfAssessmentsDocs.filter(
          (doc) => doc.id === selfAssessment.documentId,
        );
        if (docById.length) {
          return { ...selfAssessment, ...docById[0] };
        }
        return selfAssessment;
      }),
    };
  }

  public patchReference(
    data: PatchReference,
    guid: string,
  ): Observable<Object> {
    return this._http
      .patch(
        `${environment.apiEndpoint}/${REFERENCE_API.patchReference(guid)}`,
        { ...data },
      )
      .pipe(catchError(this._handleError));
  }

  public submitReference(
    data: PatchReference,
    guid: string,
    dbType: string,
  ): Observable<Object> {
    return this._http
      .patch(
        `${environment.apiEndpoint}/${REFERENCE_API.submitReference(guid, dbType)}`,
        { ...data },
      )
      .pipe(catchError(this._handleError));
  }

  public autoSave(finalize = false): Observable<Object> {
    const reference = this.reference$.value;
    if (reference?.guid) {
      const data = {
        referenceNotProvidedReason: reference.reason,
        providerType: reference.providerType,
        refereeRejectionReason: reference.refereeRejectionReason,
        refereeConfirmedData: reference.refereeConfirmedData,
        candidateRejectionReason: reference.candidateRejectionReason,
        candidateConfirmedData: reference.candidateConfirmedData,
        yourReferenceStatus: reference.yourReferenceStatus,
        selfAssessmentsDocs: this.filterSelfAssessmentsDoc(
          reference.selfAssessments,
        ),
        activePage: reference.activePage,
      };
      return finalize
        ? this.submitReference(data, reference.guid, reference.dbType)
        : this.patchReference(data, reference.guid);
    }
  }

  public filterSelfAssessmentsDoc(
    selfAssessments: SelfAssessment[],
  ): SelfAssessmentDoc[] {
    return selfAssessments
      .filter((selfAssessment) => selfAssessment.status)
      .map(
        (selfAssessment): SelfAssessmentDoc => ({
          id: selfAssessment.documentId,
          rejectionAnswers: selfAssessment.rejectionAnswers,
          status: selfAssessment.status,
        }),
      );
  }

  public handleRedirection(): void {
    if (!this.skipRedirect) {
      const reference = this.reference$.value;
      const baseUrl = `referees/${reference.dbType}_${reference.guid}`;
      switch (true) {
        case reference.referenceStatus === 'canceled':
        case reference.activePage === 3:
          this._router.navigate([`${baseUrl}/thank-you`]);
          break;
        case !reference.activePage:
        case reference.activePage === 1:
          this._router.navigate([`${baseUrl}`]);
          break;
        case reference.activePage === 2:
          this._router.navigate([`${baseUrl}/complete-your-reference`]);
          break;
      }
    }
    this.skipRedirect = false;
  }

  public removeFile(
    guid: string,
    dbType: string,
    documentId: string,
  ): Observable<Object> {
    return this._http
      .delete(
        `${environment.apiEndpoint}/${REFERENCE_API.removeFile(guid, documentId)}`,
      )
      .pipe(switchMap(() => this.getReference(guid, dbType)));
  }

  private _handleError(err: HttpErrorResponse): Observable<never> {
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Backend returned code ${err.status}: ${err.message}`;
    }
    console.error(err);
    return throwError(() => errorMessage);
  }
}
