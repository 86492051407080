<header [class.sticky]="sticky" [class.notification]="installer">
  <div class="wrapper" *ngIf="scope$ | async as scope">
    <svg-icon
      *ngIf="scope.value === 'tns'"
      id="logo-tns"
      [routerLink]="['/dashboard']"
    ></svg-icon>
    <svg-icon
      *ngIf="['sng', 'sngroi'].indexOf(scope.value) > -1"
      id="logo-sng"
      [routerLink]="['/dashboard']"
    ></svg-icon>

    <div
      class="header-cta"
      *ngIf="
        !authService.isAuthenticated() &&
        (authButtons$ | async) &&
        !maintenance &&
        !hideHeaderNav
      "
    >
      <div class="row">
        <app-button
          class="col"
          text="Register"
          className="mat-flat-button mat-small-button mat-no-margin light"
          (clicked)="
            profileFormService.navigateByButton(
              $event,
              [
                registerLinks[
                  ['sng', 'sngroi', 'sngni'].indexOf(scope.value) > -1
                    ? 'sngRegister'
                    : 'tnsRegister'
                ]
              ],
              true
            )
          "
        >
        </app-button>
        <app-button
          class="col"
          text="Login"
          className="mat-flat-button mat-small-button mat-no-margin light"
          (clicked)="
            profileFormService.navigateByButton($event, ['/authentication'])
          "
        >
        </app-button>
      </div>
    </div>
  </div>
</header>
<app-meter [class.sticky]="sticky"></app-meter>
