import { Component, Input } from '@angular/core';

@Component({
  selector: 'button:not([native-button])',
  standalone: true,
  template: `<ng-content></ng-content>`,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.mat-stroked-button]': 'stroked',
    '[class.mat-flat-button]': '!stroked',
    '[class.mat-full-width]': 'fullWidth',
    class: 'mat-button',
  },
  styles: [
    `
      :host {
        overflow: hidden;

        &[disabled] {
          cursor: default;
        }
      }
    `,
  ],
})
export class ButtonDirective {
  @Input()
  public fullWidth = false;

  @Input()
  public stroked = false;
}
