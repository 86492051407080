import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { Dayjs } from 'dayjs';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TimeUnits } from '@rp/models';
import { DateHelperService } from '@rp/services/date-helper';
import { DecimalPipe, NgIf, UpperCasePipe } from '@angular/common';

@Component({
  selector: 'app-countdown',
  template: `
    <div class="time-container">
      <ng-container *ngIf="!isOverdue; else overdue">
        <div class="title">Time left to book</div>
        <div class="subtitle" *ngIf="!countdown; else countdownTable">
          {{ 'Calculating...' | uppercase }}
        </div>
        <ng-template #countdownTable>
          <table class="countdown-table">
            <tr class="countdown-table--remaining">
              <td>{{ countdown.days | number: '2.0-0' }}</td>
              <td>:</td>
              <td>{{ countdown.hours | number: '2.0-0' }}</td>
              <td>:</td>
              <td>{{ countdown.minutes | number: '2.0-0' }}</td>
              <td>:</td>
              <td>{{ countdown.seconds | number: '2.0-0' }}</td>
            </tr>
            <tr class="countdown-table--labels">
              <td>{{ 'days' | uppercase }}</td>
              <td></td>
              <td>{{ 'hours' | uppercase }}</td>
              <td></td>
              <td>{{ 'minutes' | uppercase }}</td>
              <td></td>
              <td>{{ 'seconds' | uppercase }}</td>
            </tr>
          </table>
        </ng-template>
      </ng-container>
      <ng-template #overdue>
        <div class="title">{{ title + ' booking' | uppercase }}</div>
        <div class="subtitle">{{ 'overdue' | uppercase }}</div>
        <div class="book-title">
          {{ 'please book your ' + title + ' as soon as possible' | uppercase }}
        </div>
      </ng-template>
    </div>
  `,
  styleUrls: ['./countdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, UpperCasePipe, DecimalPipe],
  standalone: true,
})
export class CountdownComponent implements OnDestroy {
  @Input() public title: string;
  @Input() public set countdownDate(date: Date | Dayjs | string) {
    const countdownDate = DateHelperService.utcToLocal(date);
    if (!DateHelperService.isValid(countdownDate)) {
      throw new Error('Date provided for countdown is invalid');
    }
    const cutOff = DateHelperService.add(countdownDate, 7, 'days');

    this.isOverdue = DateHelperService.isAfter(DateHelperService.now(), cutOff);

    if (!this.isOverdue) {
      interval(1000)
        .pipe(takeUntil(this._destroy$))
        .subscribe(() => {
          this.countdown = this._millisecondsToUnits(
            cutOff.diff(DateHelperService.now()),
          );
          this.isOverdue =
            this.countdown.days < 1 &&
            this.countdown.hours < 1 &&
            this.countdown.minutes < 1 &&
            this.countdown.seconds < 1;
          this._cd.markForCheck();
          if (this.isOverdue) {
            this._clearInterval();
          }
        });
    }
  }

  private readonly _destroy$: Subject<void> = new Subject();

  public isOverdue = false;
  public countdown: TimeUnits;

  /* eslint-disable @typescript-eslint/naming-convention */
  private readonly _DAYS = 1000 * 60 * 60 * 24;
  private readonly _HOURS = this._DAYS / 24;
  private readonly _MINUTES = this._HOURS / 60;
  private readonly _SECONDS = this._MINUTES / 60;

  constructor(private _cd: ChangeDetectorRef) {}

  public ngOnDestroy(): void {
    this._clearInterval();
  }

  private _clearInterval(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _millisecondsToUnits(milliseconds: number): TimeUnits {
    return {
      days: Math.floor(milliseconds / this._DAYS),
      hours: Math.floor((milliseconds % this._DAYS) / this._HOURS),
      minutes: Math.floor((milliseconds % this._HOURS) / this._MINUTES),
      seconds: Math.floor((milliseconds % this._MINUTES) / this._SECONDS),
    };
  }
}
