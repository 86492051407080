function getDBByDbType(dbType: string): 'SNG' | 'TNS' {
  return dbType === '1' ? 'SNG' : 'TNS';
}

const TNS_QUICKNURSE_API = 'tns-layer/reference/v0/quicknurse';

export const REFERENCE_API = {
  getReference: (guid: string, dbType: string) =>
    `${TNS_QUICKNURSE_API}/details/${guid}/${getDBByDbType(dbType)}`,
  patchReference: (guid: string) => `${TNS_QUICKNURSE_API}/draft/${guid}`,
  submitReference: (guid: string, dbType: string) =>
    `${TNS_QUICKNURSE_API}/draft/${guid}/submission/${getDBByDbType(dbType)}`,
  getSelfAssessmentFile: (guid: string, dbType: string, documentId: number) =>
    `${TNS_QUICKNURSE_API}/selfAssessmentFile/${guid}/${documentId}/${getDBByDbType(dbType)}`,
  removeFile: (guid: string, documentId: string) =>
    `${TNS_QUICKNURSE_API}/delete/${guid}/${documentId}`,
};
