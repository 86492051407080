import { Observable, Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { SvgIconComponent } from '@rp/components/svg-icon-component';
import { RouterLinkWithHref } from '@angular/router';
import { ApplicationFormHelperService } from '@rp/services/application-form-helper';
import { fromApplicationForm } from '../../../features/application-form/store/reducers';
import { fromApplicationFormSelectors } from '../../../features/application-form/store/selectors';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  imports: [
    NgForOf,
    NgClass,
    SvgIconComponent,
    AsyncPipe,
    NgIf,
    RouterLinkWithHref,
  ],
  standalone: true,
})
export class ProgressBarComponent implements OnInit, OnDestroy {
  private _subscription: Subscription = new Subscription();

  constructor(private _store: Store<fromApplicationForm.State>) {}

  public currentSection: string;

  public sectionsOrder: string[];

  public sectionsLabels: { [key: string]: string };

  public ngOnInit(): void {
    this.sectionsOrder = ApplicationFormHelperService.sectionsOrder;
    this.sectionsLabels = ApplicationFormHelperService.sectionsLabels;

    this._subscription.add(
      this._store
        .pipe(select(fromApplicationFormSelectors.selectStep))
        .subscribe((step: string) => (this.currentSection = step)),
    );
  }

  public isSectionValid(id: string): Observable<boolean> {
    return this._store.pipe(
      select(fromApplicationFormSelectors.isSectionValid(id)),
    );
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
