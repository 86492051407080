import { NgModule } from '@angular/core';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { LoggingService } from './logging.service';

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const config: any = {
  level: NgxLoggerLevel['TRACE'],
};

@NgModule({
  imports: [LoggerModule.forRoot(config)],
  providers: [LoggingService],
})
export class LoggingModule {}
