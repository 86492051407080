import { Injectable, OnDestroy } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class LoggingService implements OnDestroy {
  private _routerSubscription: Subscription;

  constructor(
    public _logger: NGXLogger,
    private _router: Router,
  ) {
    this._routerSubscription = this._router.events
      .pipe(filter((event) => event instanceof ResolveEnd))
      .subscribe((event: ResolveEnd) => {
        const activatedComponent = this._getActivatedComponent(
          event.state.root,
        );
        if (activatedComponent) {
          this.info(
            `${activatedComponent.name}` + ' - ' + event.urlAfterRedirects,
          );
        }
      });
  }

  public ngOnDestroy(): void {
    this._routerSubscription?.unsubscribe();
  }

  public debug(message, additional?): void {
    this._call('debug', message, additional);
  }

  public info(message, additional?): void {
    this._call('info', message, additional);
  }

  public log(message, additional?): void {
    this._call('log', message, additional);
  }

  public warn(message, additional?): void {
    this._call('warn', message, additional);
  }

  public error(message, additional?): void {
    this._call('error', message, additional);
  }

  private _call(type, message, additional): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    additional
      ? this._logger[type](message, JSON.stringify(additional))
      : this._logger[type](message);
  }

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this._getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }
}
