import { ActionReducer, State } from '@ngrx/store';
import {
  localStorageSync,
  rehydrateApplicationState,
} from 'ngrx-store-localstorage';
import { HydrationActions } from '../actions';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export function localStorageSyncReducer(
  reducer: ActionReducer<any>,
): ActionReducer<any> {
  return (state: State<any>, action: any) => {
    const keys = ['applicationForm'];

    if (
      action.type === HydrationActions.STORAGE &&
      keys.includes(action.payload)
    ) {
      const rehydratedState = rehydrateApplicationState(
        [action.payload],
        localStorage,
        (k) => k,
        true,
      );
      return { ...state, ...rehydratedState };
    }

    return localStorageSync({
      keys,
      rehydrate: true,
      storage: sessionStorage,
    })(reducer)(state, action);
  };
}
