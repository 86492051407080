import { Component, OnInit, Renderer2 } from '@angular/core';
import { ResolveEnd, Router } from '@angular/router';
import { filter, map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Meta } from '@angular/platform-browser';
import { environment } from 'environment';
import { UpdateService } from '@rp/services/update';
import { ProfileService } from '@rp/services/profile';
import { LoggingService } from '@rp/services/logging';
import { TagManagerService } from '@rp/services/tag-manager';
import { AuthService } from '@rp/services/auth';
import { HydrationActions } from '@rp/store/actions';

@Component({
  selector: 'app-root',
  template: `
    <div
      id="wrapper"
      [ngClass]="{
        authenticated: authService.isAuthenticated() && !maintenance,
        notification:
          authService.isAuthenticated() && notification && !maintenance,
        application:
          !authService.isAuthenticated() && isApplicationForm && !maintenance
      }"
    >
      <app-pwa-install
        *ngIf="
          authService.isAuthenticated() &&
          showPWA &&
          !maintenance &&
          !isAccountCheckView
        "
        (installer)="notification = $event"
      ></app-pwa-install>
      <app-header *ngIf="!isAccountCheckView"></app-header>
      <main class="wrapper">
        <router-outlet
          appSeo
          (activate)="scrollToTop()"
          *ngIf="!maintenance; else maintenancePage"
        ></router-outlet>
        <ng-template #maintenancePage>
          <app-maintenance></app-maintenance>
        </ng-template>
        <app-navigation
          *ngIf="
            authService.isAuthenticated() && !maintenance && !isAccountCheckView
          "
        ></app-navigation>
        <app-progress-bar
          *ngIf="
            !authService.isAuthenticated() && isApplicationForm && !maintenance
          "
        ></app-progress-bar>
      </main>
      <!--            <app-footer *ngIf="!authService.isAuthenticated()"></app-footer>-->
    </div>
  `,
})
export class AppComponent implements OnInit {
  public notification: boolean;
  public showPWA: boolean;
  public isApplicationForm: boolean;
  public isAccountCheckView: boolean;
  public readonly maintenance: boolean = environment.maintenance;

  constructor(
    public authService: AuthService,
    private _router: Router,
    private _sw: UpdateService,
    private _metaService: Meta,
    private _profileService: ProfileService,
    private _loggingService: LoggingService,
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    private readonly _store: Store<any>,
    private readonly _renderer: Renderer2,
    private _tagManagerService: TagManagerService,
  ) {
    this._tagManagerService.addGtmToDom().pipe(take(1)).subscribe();
    this._addUserIdToDataLayer();
  }

  public ngOnInit(): void {
    if (environment.pwaEnabled)
      this._profileService.sectionStatus$.subscribe((resp) => {
        if (resp) {
          const section = resp.filter(
            (o) => o.id === 'screening-call' && o.state === 'COMPLETE',
          );
          if (section.length) {
            this._loggingService.log('Show the PWA Install prompt');
            this.showPWA = true;
          }
        }
      });

    this._router.events
      .pipe(filter((event) => event instanceof ResolveEnd))
      .subscribe((event: ResolveEnd) => {
        this.isAccountCheckView =
          event.urlAfterRedirects.indexOf('account-check') > -1;
        this.isApplicationForm =
          event.urlAfterRedirects.indexOf('application-form') > -1 &&
          !this.isAccountCheckView;
      });

    this.cacheSVG(window, document);

    this._metaService.updateTag(
      {
        name: 'viewport',
        content: `height=${window.innerHeight}, width=${window.innerWidth}, initial-scale=1, maximum-scale=1, user-scalable=0`,
      },
      `name='viewport'`,
    );

    this._sw.checkForUpdates();

    // Setup state re-hydration data sync between tabs
    this._renderer.listen('window', 'storage', (event) =>
      this._store.dispatch(new HydrationActions.Storage(event.key)),
    );
  }

  public scrollToTop(): void {
    if (window.pageYOffset > 0) {
      setTimeout(() => {
        window.scroll(0, 0);
      }, 200);
    }
  }

  public cacheSVG(window, document): void {
    const file = 'assets/svg-defs.svg',
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      revision = 1 as any,
      debug = 'true';

    // eslint-disable-next-line prefer-const
    let isLocalStorage =
        'localStorage' in window && window.localStorage !== null,
      request,
      data,
      // eslint-disable-next-line prefer-const
      insertIT = (): void => {
        document.body.insertAdjacentHTML('afterbegin', data);
      },
      // eslint-disable-next-line prefer-const
      insert = (): void => {
        if (document.body) {
          insertIT();
        } else {
          document.addEventListener('DOMContentLoaded', insertIT);
        }
      };

    if (
      isLocalStorage &&
      localStorage.getItem('inlineSVGrev') === revision &&
      // @ts-ignore
      debug === 'false'
    ) {
      data = localStorage.getItem('inlineSVGdata');

      if (data) {
        insert();
      }
    }

    try {
      request = new XMLHttpRequest();
      request.open('GET', file, true);
      request.onload = () => {
        if (request.status >= 200 && request.status < 400) {
          data = request.responseText;
          insert();

          if (isLocalStorage) {
            localStorage.setItem('inlineSVGdata', data);
            localStorage.setItem('inlineSVGrev', revision);
          }
        }
      };
      request.send();
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  private _addUserIdToDataLayer(): void {
    this._profileService
      .section('application-form')
      .pipe(
        map((section) => section.values['staff_id'][0] as number),
        take(1),
      )
      .subscribe((staffId) => {
        this._tagManagerService.addUserIdToDataLayer(staffId);
      });
  }
}
