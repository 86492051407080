<button
  id="{{ id }}"
  [attr.type]="type"
  (click)="handleClick($event)"
  [ngClass]="{ 'mat-cta-icon': cta }"
  class="mat-button {{ className }}"
  [disabled]="disabled"
  mat-button
>
  <mat-icon *ngIf="icon" [class.right]="iconAfterText">{{ icon }}</mat-icon>
  <div class="mat-center-icon" *ngIf="cta"></div>
  <span class="mat-button-label">{{ text }}</span>
  <svg-icon
    *ngIf="svgIcon"
    [id]="svgIcon"
    [className]="'icon-' + svgIcon"
  ></svg-icon>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="icon-arrow-right-circle"
    *ngIf="cta"
    [class.right]="iconAfterText"
  >
    <use
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xlink:href="#icon-arrow-right-circle"
    />
  </svg>

  <svg
    xmlns="http://www.w3.org/2000/svg"
    *ngIf="ctaLink"
    class="icon-link"
    [class.right]="iconAfterText"
  >
    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-link" />
  </svg>
</button>
