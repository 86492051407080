import { RouterReducerState } from '@ngrx/router-store';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { Params } from '@angular/router';
import { RouterStateUrl } from '../reducers';

const getRouterFeatureState: MemoizedSelector<
  object,
  RouterReducerState<RouterStateUrl>
> = createFeatureSelector<RouterReducerState<RouterStateUrl>>('router');

export const selectRouterState: MemoizedSelector<object, RouterStateUrl> =
  createSelector<object, RouterReducerState<RouterStateUrl>, RouterStateUrl>(
    getRouterFeatureState,
    (state: RouterReducerState<RouterStateUrl>) => state?.state,
  );

export const selectRouteUrl: MemoizedSelector<object, string> = createSelector<
  object,
  RouterStateUrl,
  string
>(selectRouterState, (state: RouterStateUrl) => state.url);

export const selectRouteParams: MemoizedSelector<object, Params> =
  createSelector<object, RouterStateUrl, Params>(
    selectRouterState,
    (state: RouterStateUrl) => state.params,
  );

export const selectRouteParam: (
  param: string,
) => MemoizedSelector<object, string> = (param: string) =>
  createSelector<object, RouterStateUrl, string>(
    selectRouterState,
    (state: RouterStateUrl) => state.params[param],
  );

export const selectQueryParams: MemoizedSelector<object, Params> =
  createSelector<object, RouterStateUrl, Params>(
    selectRouterState,
    (state: RouterStateUrl) => state.queryParams,
  );

export const selectQueryParam: (
  queryParam: string,
) => MemoizedSelector<object, string> = (queryParam: string) =>
  createSelector<object, RouterStateUrl, string>(
    selectRouterState,
    (state: RouterStateUrl) => state.queryParams[queryParam],
  );
