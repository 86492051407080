import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { StorageService } from '@rp/services/storage';

@Injectable({ providedIn: 'root' })
export class WelcomePackGuard implements CanActivate, CanActivateChild {
  constructor(
    private _storageService: StorageService,
    private _router: Router,
  ) {}

  public canActivate(): boolean {
    const tutorialSeen = this._storageService.get('tutorialSeen');

    if (!tutorialSeen) {
      this._router.navigate(['welcome-pack']);
      return false;
    }
    return true;
  }

  public canActivateChild(): boolean {
    return this.canActivate();
  }
}
