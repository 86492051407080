import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { environment } from 'environment';
import { combineLatest, merge, Observable, of, Subscription } from 'rxjs';
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Consultant, Section, User } from '@rp/models';
import { DivisionMaskService } from '@rp/services/division-mask';
import { ScopeService } from '@rp/services/scope';
import { ConsultantService } from '@rp/services/consultant';
import { LoggingService } from '@rp/services/logging';
import { ProfileService } from '@rp/services/profile';
import { SvgIconComponent } from '@rp/components/svg-icon-component';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from '@rp/services/auth';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  imports: [
    SvgIconComponent,
    RouterLink,
    RouterLinkActive,
    NgIf,
    AsyncPipe,
    MatIconModule,
  ],
  standalone: true,
})
export class NavigationComponent implements OnInit, OnDestroy {
  @ViewChild('navigation', { static: false }) public navigation: ElementRef;
  public consultant: Consultant;
  public consultantImage = true;
  public environment = environment;
  public navActive: boolean;
  public showConsultantHelpCall: boolean;
  public user: User;
  public scope$ = this.scopeService.data$;
  public showDashboardNav$ = this._showDashboardNav$();
  private _subscription: Subscription = new Subscription();

  constructor(
    public divisionMaskService: DivisionMaskService,
    public scopeService: ScopeService,
    private _authService: AuthService,
    private _consultantService: ConsultantService,
    private _loggingService: LoggingService,
    private _profileService: ProfileService,
    private _renderer: Renderer2,
    private _router: Router,
  ) {}

  public ngOnInit(): void {
    this._enquire();
    this._getHelpCallSection();
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public closeNav(): void {
    if (window.innerWidth < 970 && this.navActive) {
      this._renderer.removeClass(document.body, 'nav-open');
      this.navActive = false;
    }
  }

  public navigateOutsideApp(path: string, inNewWindow: boolean = false): void {
    window.open(path, inNewWindow ? '_blank' : '');
  }

  public toggleNav(): void {
    this.navActive = !this.navActive;
    this._renderer[this.navActive ? 'addClass' : 'removeClass'](
      document.body,
      'nav-open',
    );
  }

  public logOut(): void {
    this._renderer.removeClass(document.body, 'nav-open');
    this._authService.logout();
  }

  public openPrivacyPolicy(scope): void {
    this.navigateOutsideApp(
      environment.divisionMask[scope.value].privacyPolicyLink,
      true,
    );
  }

  // Respond to media queries
  private _enquire(): void {
    window.addEventListener('resize', () => {
      if (window.innerWidth > 970) {
        if (document.body.classList.contains('nav-open')) {
          this._renderer.removeClass(document.body, 'nav-open');
        }

        if (this.navActive) {
          this.navActive = false;
        }
      }
    });
  }

  private _getHelpCallSection(): void {
    const screeningCall$: Observable<Section> =
      this._profileService.section('screening-call');
    const bookAHelpCall$: Observable<Section> =
      this._profileService.section('book-a-help-call');

    this._subscription.add(
      combineLatest([screeningCall$, bookAHelpCall$]).subscribe(
        ([screeningCall, bookAHelpCall]: [Section, Section]) => {
          const [consultantId] =
            bookAHelpCall.values['book-a-help-call_consultant_id'];
          this.user = {
            name:
              bookAHelpCall.values.first_name +
              ' ' +
              bookAHelpCall.values.surname,
            email: bookAHelpCall.values.email_address,
          };

          if (screeningCall) {
            this.showConsultantHelpCall =
              screeningCall.state === 'COMPLETE' &&
              screeningCall.values['recruitment_status'][0] !== 'current';
          }

          if (consultantId) {
            this.consultant = this._consultantService.consultant(consultantId);
            this._loggingService.log('consultant', this.consultant);
          }
        },
      ),
    );
  }

  private _showDashboardNav$(): Observable<boolean> {
    const routerEvents$ = this._router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((e: NavigationEnd) => e.url),
    );
    const routerUrl$ = of(this._router.url);

    return merge(routerUrl$, routerEvents$).pipe(
      map((url) => !url.includes('welcome')),
    );
  }
}
