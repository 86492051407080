import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'environment';
import { StoreModule } from '@ngrx/store';
import { JwtModule } from '@auth0/angular-jwt';
import { provideCommonInterceptors } from '@rp/providers/interceptors';
import { HttpClientModule } from '@angular/common/http';
import { LoggingModule } from '@rp/services/logging';
import { PwaInstallComponent } from '@rp/components/pwa-install';
import { HeaderComponent } from '@rp/components/header';
import { SeoDirective } from '@rp/directives/seo';
import { MaintenanceComponent } from '@rp/components/maintenance';
import { NavigationComponent } from '@rp/components/navigation';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ProgressBarComponent } from '@rp/components/progress-bar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  CustomSerializer,
  initialState,
  metaReducers,
  reducers,
} from './store/reducers';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: null,
      },
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      initialState: initialState,
      runtimeChecks: { strictActionImmutability: true },
    }),
    StoreRouterConnectingModule.forRoot(),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 25,
          name: 'Recruitment Portal Store',
        })
      : [],
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.pwaEnabled,
    }),
    HammerModule,
    HttpClientModule,
    LoggingModule,
    PwaInstallComponent,
    HeaderComponent,
    SeoDirective,
    MaintenanceComponent,
    NavigationComponent,
    ProgressBarComponent,
    MatNativeDateModule,
    MatDialogModule,
  ],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    ...provideCommonInterceptors(),
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
