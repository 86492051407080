import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReferenceService } from './services/reference/reference.service';
import { Reference } from './interfaces/referees.interface';

@Injectable({
  providedIn: 'root',
})
export class ReferencesResolver implements Resolve<{ data: Reference[] }> {
  constructor(
    private _referenceService: ReferenceService,
    private _router: Router,
  ) {}

  public resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<{ data: Reference[] }> {
    const [dbType, guid] = route.params.id.split('_');
    return this._referenceService.getReference(guid, dbType).pipe(
      catchError((error) => {
        console.log(`Retrieval error: ${error}`);
        this._router.navigate(['/dashboard']);
        return of(null);
      }),
    );
  }
}
