<div class="nav-burger" [class.active]="navActive">
  <div
    id="mobile-nav-button"
    class="nav-burger-button"
    [class.active]="navActive"
    (click)="toggleNav()"
    (keydown.enter)="toggleNav()"
  >
    <span></span>
    <span></span>
    <span></span>
  </div>
</div>
<div
  #navigation
  id="navigation"
  class="nav-container"
  [class.active]="navActive"
>
  <div class="nav">
    <div class="nav__user">
      <div class="nav__user--icon">
        <svg-icon id="avatar1"></svg-icon>
      </div>
      <div class="nav__user--details" *ngIf="user">
        <h4>{{ user.name }}</h4>
      </div>
    </div>
    <div class="nav__items">
      <ul>
        <li
          [routerLink]="['/dashboard']"
          routerLinkActive="active"
          *ngIf="showDashboardNav$ | async"
          (click)="closeNav()"
          (keydown.enter)="closeNav()"
        >
          <svg-icon id="home"></svg-icon>
          <span>Dashboard</span>
        </li>
        <li
          [routerLink]="['/help-centre']"
          routerLinkActive="active"
          (click)="closeNav()"
          (keydown.enter)="closeNav()"
        >
          <svg-icon id="question-mark"></svg-icon>
          <span>Help Centre</span>
        </li>
        <li
          [routerLink]="['/contact-us']"
          routerLinkActive="active"
          (click)="closeNav()"
          (keydown.enter)="closeNav()"
        >
          <svg-icon id="contact"></svg-icon>
          <span>Contact Us</span>
        </li>
        <li
          *ngIf="scope$ | async as scope"
          routerLinkActive="active"
          (click)="
            closeNav();
            navigateOutsideApp(
              scope.value === 'tns'
                ? 'https://thornbury-nursing.com/blog/'
                : 'https://scottish-nursing-guild.com/blog/',
              true
            )
          "
          (keydown.enter)="
            closeNav();
            navigateOutsideApp(
              scope.value === 'tns'
                ? 'https://thornbury-nursing.com/blog/'
                : 'https://scottish-nursing-guild.com/blog/',
              true
            )
          "
        >
          <svg-icon id="blog"></svg-icon>
          <span>Blog</span>
        </li>
        <li (click)="logOut()" (keydown.enter)="logOut()">
          <svg-icon id="logOut"></svg-icon>
          <span>Log out</span>
        </li>
        <li
          *ngIf="scope$ | async as scope"
          (click)="openPrivacyPolicy(scope)"
          (keydown.enter)="openPrivacyPolicy(scope)"
          class="privacy-policy"
        >
          <span>Privacy policy</span>
          <mat-icon>open_in_new</mat-icon>
        </li>
      </ul>
    </div>
    <div class="nav__support">
      <div class="nav__support--contact">
        <p class="nav__support--policy">
          Please note that all calls are recorded for training and quality
          purposes.
        </p>
        <ng-container *ngIf="showConsultantHelpCall && consultant">
          <span class="nav__support--contact-header">Your Contact</span>
          <p>
            Hi, I’m {{ consultant.displayName }}, Recruitment Consultant at
            <span
              *ngIf="
                (scopeService.data$ | async).title === 'Thornbury';
                else SNG
              "
              >Thornbury Nursing Services.</span
            >
            <ng-template #SNG><span>Scottish Nursing Guild.</span></ng-template>
            I’ll be your dedicated contact, here to guide you through the
            onboarding process. If you need any help or advice just give us a
            call on
            <a
              class="link"
              href="tel:{{
                environment.divisionMask[
                  divisionMaskService.divisionMask$ | async
                ]?.telephone
              }}"
              >{{
                environment.divisionMask[
                  divisionMaskService.divisionMask$ | async
                ]?.telephone
              }}</a
            >
          </p>
          <div class="nav__user nav__support--contact-user">
            <div class="nav__user--icon support--contact-icon">
              <img
                *ngIf="consultantImage"
                src="/assets/images/consultants/{{ consultant.id }}.png"
                alt="Image for consultant {{ consultant.id }}"
                (error)="consultantImage = false"
              />
              <img
                *ngIf="!consultantImage"
                src="/assets/icons/icon-192x192.png"
                alt="No consultant"
              />
            </div>
            <div class="nav__user--details support--contact-details">
              <h4>{{ consultant.name }}</h4>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
