<h1 class="light">Your application progress</h1>
<div *ngFor="let section of sectionsOrder; let i = index">
  <div
    class="vertical-bar"
    [ngClass]="{
      'vertical-bar--completed': i <= sectionsOrder.indexOf(currentSection),
      'vertical-bar--incomplete': i > sectionsOrder.indexOf(currentSection)
    }"
  ></div>
  <div class="list-item">
    <svg-icon
      [id]="
        (isSectionValid(sectionsOrder[i]) | async) ? 'tick' : 'circle-outline'
      "
    ></svg-icon>
    <a
      *ngIf="
        i === 0 || (isSectionValid(sectionsOrder[i - 1]) | async);
        else noLink
      "
      class="section-name"
      [ngClass]="
        (isSectionValid(sectionsOrder[i]) | async)
          ? 'section-name--completed'
          : 'section-name--incomplete'
      "
      [innerHtml]="sectionsLabels[section]"
      [routerLink]="['application-form', section]"
      queryParamsHandling="preserve"
    >
    </a>
    <ng-template #noLink>
      <span
        class="section-name"
        [innerHtml]="sectionsLabels[section]"
        [ngClass]="
          (isSectionValid(sectionsOrder[i]) | async)
            ? 'section-name--completed'
            : 'section-name--incomplete'
        "
      ></span>
    </ng-template>
  </div>
</div>
