import { Directive, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { distinct, filter, map, switchMap } from 'rxjs/operators';
import { environment } from 'environment';
import { Subscription } from 'rxjs';
import { ScopeService } from '@rp/services/scope';

@Directive({
  selector: '[appSeo]',
  standalone: true,
})
export class SeoDirective implements OnInit, OnDestroy {
  private _routerSubscription$: Subscription = new Subscription();
  private _scope: string;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _meta: Meta,
    private _title: Title,
    private _scopeService: ScopeService,
  ) {}

  public ngOnInit(): void {
    this._scopeService.data$.subscribe(({ value }) => {
      this._scope = value;
    });

    this._routerSubscription$.add(
      this._router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this._activatedRoute),
          map((route) => {
            while (route.firstChild) {
              if (Object.keys(route.snapshot.data).length > 0) {
                return route;
              }
              route = route.firstChild;
            }
            return route;
          }),
          filter((route) => route.outlet === 'primary'),
          switchMap((route) => route.data),
          distinct(),
          map((routeData) => routeData?.data),
        )
        .subscribe((resolveData) => {
          const seo_data = resolveData?.seo ?? null;
          const upsertTag = (
            selector: string,
            metaDefinition: MetaDefinition,
          ): void => {
            if (!this._meta.getTag(selector)) {
              this._meta.addTag(metaDefinition);
              return;
            }
            this._meta.updateTag(metaDefinition, selector);
          };
          const title =
            seo_data?.title ?? environment.seo[`${this._scope}Title`];
          const description: MetaDefinition = {
            name: 'description',
            content:
              seo_data?.description ??
              environment.seo[`${this._scope}Description`],
          };
          const robots: MetaDefinition = {
            name: 'robots',
            content: seo_data?.robots ?? environment.seo.robots,
          };

          this._title.setTitle(title);

          upsertTag(`name='description'`, description);
          upsertTag(`name='robots'`, robots);
        }),
    );
  }

  public ngOnDestroy(): void {
    if (this._routerSubscription$) {
      this._routerSubscription$.unsubscribe();
    }
  }
}
