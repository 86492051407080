import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScopeService } from '@rp/services/scope';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
  imports: [NgIf, AsyncPipe],
  standalone: true,
})
export class MaintenanceComponent {
  public readonly title$: Observable<string>;

  constructor(private _scopeService: ScopeService) {
    this.title$ = this._scopeService.data$.pipe(map((data) => data.title));
  }
}
