import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Dayjs } from 'dayjs';
import { DashboardTileConfigOptions, Section, SectionId } from '@rp/models';
import { ProfileFormService } from '@rp/services/profile-form';
import { DivisionMaskService } from '@rp/services/division-mask';
import { DateHelperService } from '@rp/services/date-helper';
import { NgClass, NgIf } from '@angular/common';
import { SvgIconComponent } from '@rp/components/svg-icon-component';
import { CountdownComponent } from './countdown/countdown.component';

export const migratedSections: SectionId[] = [
  'interview',
  'workbook',
  'work-%26-education-history',
  'declarations',
  'screening-call',
  'id-verification',
  'competency-record',
  'references-%26-self-assessment',
  'disclosure',
  'occupational-health',
];

@Component({
  selector: 'app-dashboard-tile',
  template: `
    <div
      class="section"
      [class.section--disabled]="isSectionDisabled(section)"
      [ngClass]="getSectionClass(section)"
    >
      <div
        class="section__main-content"
        [ngClass]="{ 'has-countdown': countdownVisible }"
      >
        <span class="title">
          {{ section.title }}
        </span>
        <span class="appointment" *ngIf="hasAppointment(section)">
          {{
            formatAppointment(section.values[section.id + '_appointment_date'])
          }}
        </span>
        <span class="tile-icon">
          <svg-icon [id]="getSvgIconId(section.id)"></svg-icon>
        </span>
        <div class="state" [ngClass]="getStateClass(section)">
          <ng-container
            *ngIf="getSectionState(section) === 'default'; else stateMessage"
          >
            <svg-icon [id]="'timer'"></svg-icon>
            <span class="state__message"
              >{{ getSectionDuration(section.id) }} mins</span
            >
          </ng-container>
          <ng-template #stateMessage>
            <span class="state__message">{{ getStateMessage(section) }}</span>
          </ng-template>
        </div>
      </div>
      <div class="section__countdown-content" *ngIf="countdownVisible">
        <app-countdown
          [title]="section.title"
          [countdownDate]="getCountdownDate(section)"
        ></app-countdown>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, NgIf, SvgIconComponent, CountdownComponent],
  standalone: true,
})
export class DashboardTileComponent {
  @Input()
  public set section(section: Section) {
    this._section = section;
    this.countdownVisible = this.showCountdown(section);
  }

  public get section(): Section {
    return this._section;
  }

  @Input()
  public tileConfigurationOptions?: DashboardTileConfigOptions;
  public countdownVisible: boolean;

  private readonly _oldToNewMapping = {
    pending: 'manage',
    incomplete: 'default',
    complete: 'approved',
  };
  private _section: Section;

  constructor(
    private _profileFormService: ProfileFormService,
    private _divisionMaskService: DivisionMaskService,
  ) {}

  public isSectionDisabled(section: Section): boolean {
    return !this._profileFormService.isSectionTileClickable(section);
  }

  public getSectionClass({
    id,
    state,
    message,
    values,
  }: Section): string | null {
    if (migratedSections.indexOf(id) !== -1)
      return this._profileFormService.getSectionStatus(id, values);

    if (message === 'in-review') return 'in-review';
    if (state.toLowerCase() === 'incomplete') return 'default';
    if (state.toLowerCase() === 'complete') return 'approved';
    return state.toLowerCase();
  }

  public hasAppointment(section: Section): boolean {
    if (this.tileConfigurationOptions?.showInterview === false) return false;
    return (
      this.getSectionState(section) === 'manage' &&
      !!section.values[section.id + '_appointment_date'] &&
      section.values[section.id + '_appointment_date'].length > 0
    );
  }

  public formatAppointment([date]: [Date | string | Dayjs]): string | null {
    const dayJsDate = DateHelperService.toDayjs(date);
    if (!DateHelperService.isValid(dayJsDate)) return null;
    return dayJsDate.format('dddd Do MMM YYYY HH:mm A');
  }

  public getSvgIconId(sectionId: string): string {
    if (sectionId.indexOf('education') !== -1)
      return 'dashboard-work-and-education-history';
    if (sectionId.indexOf('references') !== -1)
      return 'dashboard-references-and-self-assessment';
    return `dashboard-${sectionId}`;
  }

  public getStateClass({ id, state, message, values }: Section): string | null {
    if (migratedSections.indexOf(id) !== -1)
      return this._profileFormService.getSectionStatus(id, values);

    if (state === 'INCOMPLETE') return 'default';
    if (state === 'PENDING' && message !== 'in-review') return 'pending';
    if (message === 'in-review') return 'in-review';
    if (state === 'ISSUE') return 'issue';
    if (state === 'COMPLETE') return 'approved';
    return null;
  }

  public getStateMessage(section: Section): string | null {
    if (migratedSections.indexOf(section.id) !== -1)
      return this._getNewStateMessage(section);

    if (section.state === 'PENDING' && section.message !== 'in-review')
      return 'Manage';
    if (section.message === 'in-review') return 'In Review';
    if (section.state === 'ISSUE') return 'Rejected';
    if (section.state === 'COMPLETE') return 'Approved';
    return null;
  }

  public getSectionState({ id, state, values }: Section): string {
    if (migratedSections.indexOf(id) !== -1)
      return this._profileFormService.getSectionStatus(id, values);

    return this._oldToNewMapping[state.toLowerCase()] ?? state.toLowerCase();
  }

  public getSectionDuration(sectionId: SectionId): number | null {
    const defaultAmount = 10;
    if (this.tileConfigurationOptions?.overrideTime)
      return this.tileConfigurationOptions?.overrideTimeAmount ?? defaultAmount;
    if (sectionId === 'pre-work-requirements') return 5;
    if (
      [
        'references-%26-self-assessment',
        'occupational-health',
        'agency-agreement',
      ].indexOf(sectionId) !== -1
    )
      return 10;
    if (
      ['work-%26-education-history', 'declarations'].indexOf(sectionId) !== -1
    )
      return 15;
    if (
      [
        'screening-call',
        'disclosure',
        'id-verification',
        'competency-record',
      ].indexOf(sectionId) !== -1
    )
      return 20;
    if (sectionId === 'interview') return 30;
    if (sectionId === 'workbook') return 45;
    return defaultAmount;
  }

  public showCountdown(section: Section): boolean {
    if (!this._showCountdownLogicGates(section.id)) return false;
    const acceptedSectionIds = ['interview', 'screening-call'];
    const sectionFns: { [key: string]: (values: Section) => boolean } = {
      interview: (sectionValues: Section) => {
        if (
          this._profileFormService.getValue(sectionValues, 'interview_type') ===
          'hirevue'
        )
          return false;
        const recruitmentStatus = this._profileFormService.getValue(
          sectionValues,
          'recruitment_status',
        );
        const interviewDate = this._profileFormService.getValue(
          sectionValues,
          'interview_appointment_date',
        );
        const preparingForInterviewTime = this._profileFormService.getValue(
          sectionValues,
          DashboardTileComponent._getCountdownField('interview'),
        );
        return (
          recruitmentStatus === 'preparing_for_interview' &&
          !interviewDate &&
          !!preparingForInterviewTime
        );
      },
      'screening-call': (sectionValues: Section) => {
        const isApproved =
          this._profileFormService.getValue(
            sectionValues,
            'screening-call_status',
          ) === 'approved';
        const appointmentDate = this._profileFormService.getValue(
          sectionValues,
          'screening-call_appointment_date',
        );
        const adminCreationTime = this._profileFormService.getValue(
          sectionValues,
          DashboardTileComponent._getCountdownField('screening-call'),
        );
        return !isApproved && !appointmentDate && !!adminCreationTime;
      },
      default: () => false,
    };

    if (acceptedSectionIds.indexOf(section.id) !== -1) {
      return (sectionFns[section.id] || sectionFns['default'])(section);
    }

    return false;
  }

  public getCountdownDate(section: Section): string {
    return this._profileFormService.getValue(
      section,
      DashboardTileComponent._getCountdownField(section.id),
    ) as string;
  }

  private _showCountdownLogicGates(sectionId: string): boolean {
    if (this.tileConfigurationOptions?.showCountdown === false) return false;
    if (['interview', 'screening-call'].indexOf(sectionId) !== -1) {
      if (
        ['sng', 'sngroi', 'sngni'].indexOf(this._divisionMaskService.get()) ===
        -1
      )
        return false;
    }
    return true;
  }

  private _getNewStateMessage(section: Section): string {
    const fieldId = this._profileFormService.getSectionStatusAttributeName(
      section.id,
    );
    const optionId = this._profileFormService.getSectionStatus(
      section.id,
      section.values,
    );
    const optionLabel = this._profileFormService.getOptionLabel(
      section,
      fieldId,
      optionId,
    );

    if (
      optionId === 'call-us' &&
      section.id === 'interview' &&
      section.values.interview_phone_number &&
      section.values.interview_phone_number.length
    ) {
      return `${optionLabel} on ${section.values.interview_phone_number[0]}`;
    }

    return optionLabel;
  }

  private static _getCountdownField(sectionId: SectionId): string {
    const countdownFields: { [key: string]: string } = {
      interview: 'recruitment_preparing_for_interview_time',
      'screening-call': 'admin_portal_created_time',
    };
    return countdownFields[sectionId];
  }
}
