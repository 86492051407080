import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { StateMessage } from '@rp/models';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessageService {
  private _errorMessages = new Subject<StateMessage>();

  public data$ = this._errorMessages.asObservable();

  public emit(data: StateMessage): void {
    this._errorMessages.next(data);
  }

  public clearErrors(): void {
    this._errorMessages.next();
  }
}
