import { Injectable } from '@angular/core';
import { LoggingService } from '@rp/services/logging';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private _loggingService: LoggingService) {}

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  public set(key: string, value?: any): any {
    this._loggingService.log(key, value);
    localStorage.setItem(key, JSON.stringify(value));
  }

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  public get(key: string): any {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      console.error('StorageService: ', e);
      return undefined;
    }
  }

  public remove(key: string): void {
    localStorage.removeItem(key);
  }

  public clear(): void {
    localStorage.clear();
  }
}
