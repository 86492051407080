import { Provider } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BasicAuthInterceptor } from '@rp/interceptors/basic-auth';
import { ErrorInterceptor } from '@rp/interceptors/error-interceptor';
import { MapsInterceptor } from '@rp/interceptors/maps';
import { DeviceInterceptor } from '@rp/interceptors/device';
import { SectionStartInterceptor } from '@rp/interceptors/section-start';
import { SubmittedTimeAndCountInterceptor } from '@rp/interceptors/submitted-time-and-count';

export function provideCommonInterceptors(): Provider[] {
  return [
    { provide: 'HOST', useValue: window.location.host },
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MapsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DeviceInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SectionStartInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SubmittedTimeAndCountInterceptor,
      multi: true,
    },
  ];
}
