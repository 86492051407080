<div class="container">
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <section class="maintenance" *ngIf="title$ | async as title">
        <h1 class="light">Maintenance</h1>
        <p>
          <span *ngIf="title === 'Thornbury'">The</span> {{ title }} Portal is
          currently down for maintenance.
        </p>
        <p>
          We expect to be back in a couple of hours. Thanks for your patience.
        </p>
      </section>
    </div>
    <div class="col-md-3"></div>
  </div>
</div>
