import { Injectable } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Attribute,
  Section,
  SectionId,
  SectionValues,
  Values,
} from '@rp/models';
import { AutoSaveStatus } from '@rp/enums';
import { migratedSections } from '@rp/components/dashboard-tile';
import { DateHelperService } from '@rp/services/date-helper';

@Injectable({
  providedIn: 'root',
})
/*eslint-disable @typescript-eslint/no-explicit-any*/
export class ProfileFormService {
  private _navigateOnRejectedSections: SectionId[] = [
    'work-%26-education-history',
    'declarations',
    'competency-record',
    'references-%26-self-assessment',
    'disclosure',
  ];
  public autoSave$: Subject<SectionValues> = new Subject<SectionValues>();
  public autoSaveStatus$: BehaviorSubject<AutoSaveStatus> =
    new BehaviorSubject<AutoSaveStatus>(AutoSaveStatus.NoSave);
  public readonly autoSaveDestroy$: Subject<void> = new Subject();

  constructor(
    private _router: Router,
    private _focusMonitor: FocusMonitor,
  ) {}

  public getSectionStatus(id: string, values: Values): string | null {
    const statusArray = <Array<string>>(
      values[this.getSectionStatusAttributeName(id)]
    );
    const hasValue = statusArray && statusArray.length > 0 && !!statusArray[0];
    return hasValue ? statusArray[0].toLowerCase() : 'default';
  }

  public isSectionTileClickable({
    message,
    state,
    id,
    values,
  }: Section): boolean {
    const sectionStatus = this.getSectionStatus(id, values);
    const notMigratedSectionsChecked =
      message === 'in-review' || state === 'COMPLETE';
    const migratedSectionsCheck =
      ['in-review', 'in_review', 'approved', 'rejected'].indexOf(
        sectionStatus,
      ) !== -1;
    const allowEnterOnRejected =
      this._navigateOnRejectedSections.includes(id) &&
      sectionStatus === 'rejected';
    const isMigratedSection = migratedSections.includes(id);
    if (isMigratedSection) {
      return !(migratedSectionsCheck && !allowEnterOnRejected);
    }
    return !notMigratedSectionsChecked;
  }

  public getSectionStatusAttributeName(id: string): string | null {
    if (id === 'work-%26-education-history') {
      id = id.replace('%26', 'and');
    }
    return `${id}_status`;
  }

  /**
   * Deep copy object
   * @param data
   * @param objMap
   */
  public static clone(data: any, objMap?: WeakMap<any, any>): any {
    if (!objMap) {
      // Map for handle recursive objects
      objMap = new WeakMap();
    }

    // recursion wrapper
    const deeper = (value): any => {
      if (value && typeof value === 'object') {
        return this.clone(value, objMap);
      }
      return value;
    };

    // Array value
    if (Array.isArray(data)) {
      return data.map(deeper);
    }

    // Object value
    if (data && typeof data === 'object') {
      // Same object seen earlier
      if (objMap.has(data)) {
        return objMap.get(data);
      }
      // Date object
      if (data instanceof Date) {
        const result = new Date(data.valueOf());
        objMap.set(data, result);
        return result;
      }
      // Use original prototype
      const node = Object.create(Object.getPrototypeOf(data));
      // Save object to map before recursion
      objMap.set(data, node);
      for (const [key, value] of Object.entries(data)) {
        node[key] = deeper(value);
      }
      return node;
    }
    // Scalar value
    return data;
  }

  /**
   * Check if object has undefined or empty values
   * @param object
   */
  public isEmpty(object): boolean {
    return Object.values(object).every(
      (x: any) => x == null || x === '' || x.length < 1,
    );
  }

  /**
   * Recursively get field from PMC
   * @param section
   * @param fieldId
   */
  public getField(section: Section, fieldId: string): Attribute {
    let field = section.fields.find((f) => f.id === fieldId);
    if (!field) {
      let i = 0;
      while (!field && i < section.fields.length) {
        if (section.fields[i].fields && section.fields[i].fields.length) {
          field = this.getField(section.fields[i], fieldId);
        }
        i++;
      }
    }
    return field;
  }

  /**
   * Return field type
   * @param id
   * @param data
   */
  public getFieldType(id, data: Section): string {
    const field = this.getField(data, id);
    if (field) {
      return field.type;
    }
  }

  /**
   * Return attribute label for field
   * @param section
   * @param fieldId
   */
  public getLabel(section: Section, fieldId: string): string {
    return this.getField(section, fieldId).label;
  }

  /**
   * Return option label from ID
   * @param section
   * @param fieldId
   * @param optionId
   */
  public getOptionLabel(
    section: Section,
    fieldId: string,
    optionId: string,
  ): string {
    if (optionId) {
      return this.getField(section, fieldId).options.find(
        (o) => o.id === optionId,
      ).label;
    }
  }

  /**
   * Return field value from PMC
   * @param data
   * @param id
   */
  public getValue(data: Section, id: string): string | number | object {
    if (data.values[id] && data.values[id].length) {
      return data.values[id][0];
    } else {
      return '';
    }
  }

  /**
   * Format date object into specified format
   * @param date
   * @param format
   * @param formatted
   */
  public formatDate(date, format: string, formatted: string): any {
    if (DateHelperService.toDayjs(date, format).isValid()) {
      return DateHelperService.toDayjs(date, format).format(formatted);
    } else {
      return date;
    }
  }

  /**
   * Format formGroup dates into specified format
   * @param group
   * @param format
   * @param formatted
   */
  public formatDates(
    group: UntypedFormGroup,
    format: string,
    formatted: string,
  ): void {
    const controls: UntypedFormGroup['controls'] = group.controls;
    for (const control in group.controls) {
      if (control.indexOf('date') !== -1) {
        if (
          DateHelperService.toDayjs(controls[control].value, format).isValid()
        ) {
          controls[control].patchValue(
            this.formatDate(controls[control].value, format, formatted),
            { emitEvent: false },
          );
        } else {
          controls[control].patchValue('', { emitEvent: false });
        }
      }
    }
  }

  /**
   * Create a profile form group
   * @param data
   */
  public createForm(data: Section): UntypedFormGroup {
    const form = new UntypedFormGroup({});
    data.fields.forEach((field) => {
      if (this.getFieldType(field.id, data) === 'group') {
        form.addControl(field.id, new UntypedFormArray([]));
        data.values[field.id].forEach((fields) => {
          if (!this.isEmpty(fields)) {
            const group = this.buildDynamicGroup(fields, field);
            (<UntypedFormArray>form.get(field.id)).push(group);
          }
        });
      } else {
        if (
          this.getFieldType(field.id, data) === 'file' &&
          data.values[field.id].length
        ) {
          data.values[field.id].find((meta) => {
            const file = this.buildDynamicGroup(meta, data);
            form.addControl(field.id, file);
          });
        } else {
          form.addControl(
            field.id,
            new UntypedFormControl(
              this.getValue(data, field.id),
              field.maxQty > 1 ? [Validators.maxLength(field.maxQty)] : [],
            ),
          );
        }
      }
    });

    return form;
  }

  /**
   * Build a dynamic group from field group
   * @param data
   * @param group
   */
  public buildDynamicGroup(data, group): UntypedFormGroup {
    const formControlFields = [];
    const formGroup: UntypedFormGroup = new UntypedFormGroup({});

    for (const i in data) {
      if (data.hasOwnProperty(i)) {
        if (this.getFieldType(i, group) === 'file' && data[i][0] != null) {
          const file = this.buildDynamicGroup(data[i][0], group);
          formControlFields.push({ name: i, control: file });
        } else if (!Array.isArray(data[i]) && data[i]) {
          formControlFields.push({
            name: i,
            control: new UntypedFormControl(data[i]),
          });
        } else {
          formControlFields.push({
            name: i,
            control: new UntypedFormControl(data[i][0] ? data[i][0] : ''),
          });
        }
      }
    }

    formControlFields.forEach((f) => formGroup.addControl(f.name, f.control));
    return formGroup;
  }

  /**
   * Recursively wrap all values in an array
   * @param data
   * @param section
   * @param format
   */
  public formatValues(data, section: Section, format?: string): Values {
    const strings = /(fileName|fileSizeBytes|dateCreated|_groupInstanceId)/;
    return Object.keys(data).reduce((acc: {}, curr: string) => {
      if (
        data[curr] &&
        typeof data[curr] === 'object' &&
        ['file', 'multiselect'].indexOf(this.getFieldType(curr, section)) < 0
      ) {
        acc[curr] = data[curr].map((child) =>
          this.formatValues(child, section, format),
        );
      } else if (curr.match(strings)) {
        acc[curr] = data[curr];
      } else if (this.getFieldType(curr, section) === 'date' && format) {
        acc[curr] = [].concat(
          this.formatDate(data[curr], format, 'YYYY-MM-DD'),
        );
      } else {
        acc[curr] = data[curr] ? [].concat(data[curr]) : [];
      }
      return acc;
    }, {});
  }

  /**
   * Return immutable object containing formatted form values for PMC
   * @param form
   * @param section
   * @param format
   */
  public buildValues(
    form: UntypedFormGroup,
    section: Section,
    format?: string,
  ): Values {
    const values = ProfileFormService.clone(form.value);
    return this.formatValues(values, section, format);
  }

  /**
   * Navigate to url and remove material focus class event programmatically
   * @param event
   * @param url
   * @param isOuterUrl
   */
  public navigateByButton(
    event: Event,
    url: string[],
    isOuterUrl: boolean = false,
  ): void {
    const button = event.currentTarget as HTMLElement;
    if (isOuterUrl) {
      window.location.href = url[0];
    } else {
      this._router
        .navigate(url)
        .then(() => this._focusMonitor.stopMonitoring(button));
    }
  }

  /**
   * Custom checkbox change event listener to patch boolean formControl with string
   * @param checked
   * @param form
   * @param id
   * @param values
   */
  public checkboxChange(
    checked: boolean,
    form: UntypedFormGroup,
    id: string,
    values: string,
  ): void {
    form.patchValue({ [id]: checked ? values : false });
    form.controls[id].setErrors(checked ? null : { required: true });
    form.controls[id].markAsDirty({ onlySelf: true });
    form.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

  /**
   * Recursively mark all controls as touched
   * @param form
   */
  public markAsTouched(form: UntypedFormGroup | UntypedFormArray): void {
    (<any>Object).keys(form.controls).forEach((key: string) => {
      const control: AbstractControl = form.controls[key];
      if (
        control instanceof UntypedFormGroup ||
        control instanceof UntypedFormArray
      ) {
        this.markAsTouched(<UntypedFormGroup | UntypedFormArray>control);
      } else if (control.value && control.invalid) {
        control.markAsTouched();
      }
    });
  }

  /**
   * Listen out for any form changes and recursively autosave data
   * @param section
   * @param form
   * @param format
   * @param msToDebounce
   */
  public autoSaveValues(
    section: Section,
    form: UntypedFormGroup,
    format?: string,
    msToDebounce: number = 1000,
  ): void {
    form.valueChanges
      .pipe(
        debounceTime(msToDebounce),
        distinctUntilChanged(),
        takeUntil(this.autoSaveDestroy$),
      )
      .subscribe((changes) => {
        const saveValid = (
          group: UntypedFormGroup | UntypedFormArray,
        ): void => {
          Object.keys(group.controls).forEach((key: string) => {
            const control: AbstractControl = group.controls[key];
            if (
              control instanceof UntypedFormGroup ||
              control instanceof UntypedFormArray
            ) {
              saveValid(<UntypedFormGroup | UntypedFormArray>control);
            } else if (
              (control.valid || typeof control.value === 'boolean') &&
              control.dirty
            ) {
              const values = ProfileFormService.clone(changes);
              control.markAsPristine();
              this.autoSave$.next({
                values: this.formatValues(values, section, format),
              });
            } else {
              return;
            }
          });
        };

        return saveValid(form);
      });
  }
}
