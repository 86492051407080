import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
/* eslint-disable @typescript-eslint/no-explicit-any */
export class MapsInterceptor implements HttpInterceptor {
  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (request.url.indexOf('map') !== -1) {
      request = request.clone({
        headers: request.headers.delete('Authorization'),
      });
    }
    return next.handle(request);
  }
}
