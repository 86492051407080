import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MeterService {
  public meter: Subject<boolean> = new Subject();

  public launch(status: boolean): void {
    this.meter.next(status);
  }
}
