import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { StorageService } from '@rp/services/storage';
import { DivisionMask } from '@rp/models';

@Injectable({
  providedIn: 'root',
})
export class DivisionMaskService {
  constructor(private _storageService: StorageService) {}

  private _divisionMaskSource = new Subject<DivisionMask>();
  public divisionMask$ = this._divisionMaskSource
    .asObservable()
    .pipe(shareReplay(1));

  private static _getDivisionMask(data): DivisionMask {
    const section = data.sections ? data.sections[0].values : data.values;
    return section.division_mask[0];
  }

  public set(value): void {
    const divisionMask = DivisionMaskService._getDivisionMask(value);
    this._storageService.set('division_mask', divisionMask || 'tns');
    this._divisionMaskSource.next(divisionMask);
  }

  public get(): DivisionMask {
    return this._storageService.get('division_mask')
      ? this._storageService.get('division_mask')
      : 'tns';
  }
}
