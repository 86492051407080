import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { pwaInstallHandler } from 'pwa-install-handler';
import { Subscription } from 'rxjs';
import { ScopeService } from '@rp/services/scope';
import { SvgIconComponent } from '@rp/components/svg-icon-component';
import { NgIf } from '@angular/common';
import { ButtonComponent } from '@rp/material/button-component';

@Component({
  selector: 'app-pwa-install',
  templateUrl: './pwa-install.component.html',
  styleUrls: ['./pwa-install.component.scss'],
  imports: [SvgIconComponent, NgIf, ButtonComponent],
  standalone: true,
})
export class PwaInstallComponent implements OnInit, OnDestroy {
  public scope: string;
  public scopes: Subscription;
  public canInstall: boolean;
  @Output() public installer: EventEmitter<boolean> = new EventEmitter();

  constructor(private _scopeService: ScopeService) {}

  public ngOnInit(): void {
    // already on the app or a standalone window
    if (!window.matchMedia('(display-mode: standalone)').matches) {
      pwaInstallHandler.addListener((canInstall) => {
        // meets BeforeInstallPrompt requirements
        if (canInstall) {
          this.getScope();
          this.canInstall = true;
          this.installer.emit(true);
        }
      });
    }
  }

  public ngOnDestroy(): void {
    if (this.scopes) {
      this.scopes.unsubscribe();
    }
  }

  public getScope(): void {
    this.scopes = this._scopeService.data$.subscribe((resp) => {
      this.scope = resp.value;
    });
  }

  public install(): void {
    pwaInstallHandler.install().then((isInstalled: boolean) => {
      if (isInstalled) {
        this.canInstall = false;
        this.installer.emit(false);
      } else {
        this.canInstall = true;
      }
    });
  }

  public close(): void {
    this.installer.emit(false);
    this.canInstall = false;
  }
}
