import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import {
  ActivatedRouteSnapshot,
  Params,
  RouterStateSnapshot,
} from '@angular/router';
import * as fromRouter from '@ngrx/router-store';
import { localStorageSyncReducer } from './hydration.reducer';

export interface RouterStateUrl {
  url: string;
  queryParams: Params;
  params: Params;
}

export interface State {
  router: fromRouter.RouterReducerState<RouterStateUrl>;
}

export const initialState: State = {
  router: {
    state: {
      url: '/',
      params: {},
      queryParams: {},
    },
    navigationId: 0,
  },
};

export const reducers: ActionReducerMap<State> = {
  router: fromRouter.routerReducer,
};
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const metaReducers: Array<MetaReducer<any, any>> = [
  localStorageSyncReducer,
];

export class CustomSerializer
  implements fromRouter.RouterStateSerializer<RouterStateUrl>
{
  public serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url }: RouterStateSnapshot = routerState;
    const { queryParams }: Params = routerState.root;

    let state: ActivatedRouteSnapshot = routerState.root;
    while (state.firstChild) {
      state = state.firstChild;
    }
    const { params }: Params = state;

    return { url, queryParams, params };
  }
}
