import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'environment';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import {
  Profile,
  ProfileError,
  Section,
  SectionId,
  SectionValues,
  StagingOptions,
} from '@rp/models';
import { ProfileFormService } from '@rp/services/profile-form';

export interface SectionStatus {
  state: string;
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private _sections$: BehaviorSubject<Section[]> = new BehaviorSubject<
    Section[]
  >([]);
  private _isProfileDataResolved = false;

  public sectionStatus$: BehaviorSubject<SectionStatus[]> = new BehaviorSubject<
    SectionStatus[]
  >(null);
  public autoSave$: Subject<SectionValues> = new Subject<SectionValues>();

  constructor(
    private _http: HttpClient,
    private _profileFormService: ProfileFormService,
  ) {}

  public getDashboard(): Observable<Profile> {
    return this.get().pipe(
      map((profile) => {
        profile.sections = profile.sections.filter(
          (section: Section) => !this.amIMuted(section, profile.sections),
        );
        return profile;
      }),
      catchError(this._handleError),
    );
  }

  public get(): Observable<Profile> {
    return this._http
      .get<Profile>(
        `${environment.apiEndpoint}/tns-layer/profile/v0/my-profile`,
      )
      .pipe(
        tap(({ sections }) => {
          this._sections$.next(sections);
          this._isProfileDataResolved = true;

          const data = sections.reduce((obj, v) => {
            obj.push({
              state: v.state,
              id: v.id,
            });
            return obj;
          }, []);

          this.sectionStatus$.next(data);
        }),
      );
  }

  public log(data: ProfileError): Observable<ProfileError> {
    return this._http.post<ProfileError>(
      `${environment.apiEndpoint}/tns-layer/profile/v0/log-error`,
      data,
    );
  }

  /**
   * PMC patch request
   * @param data
   * @param stagingOptions
   */
  public save(
    data,
    { stagingId = '', stagingBaseUrl }: StagingOptions,
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    return this._http.patch(
      `${environment.apiEndpoint}/${stagingBaseUrl}/my-profile?stagingId=${stagingId}`,
      data,
    );
  }

  /**
   * TODO - Add interface
   */
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  public patch(data): Observable<any> {
    return this._http.patch(
      `${environment.apiEndpoint}/tns-layer/profile/v0/my-profile`,
      data,
    );
  }

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  public submit(data, exports: boolean): Observable<any> {
    return this._http.patch(
      `${environment.apiEndpoint}/tns-layer/profile/v0/my-profile?export=${exports}`,
      data,
    );
  }

  public section(id: SectionId): Observable<Section> {
    return this._sections$.pipe(
      map((sections) => sections.find((section) => section.id === id)),
      filter((section) => !!section),
    );
  }

  public resolve(): Observable<boolean> {
    if (!this._isProfileDataResolved) {
      return this.get().pipe(map(() => true));
    }
    return of(true);
  }

  public amIMuted(section: Section, sections: Section[]): boolean {
    const sectionConfig = environment.pathsConfig.find(
      (o) => o.id === section.id,
    );
    if (sectionConfig && sectionConfig.muteOutright) {
      return true;
    } else if (
      sectionConfig &&
      sectionConfig.divisionMaskMute.indexOf(
        section.values.division_mask && section.values.division_mask[0],
      ) > -1
    ) {
      return true;
    } else if (sectionConfig && sectionConfig.sectionsRequiredMute.length) {
      let resp = false;
      // Work with sections to establish section status
      sectionConfig.sectionsRequiredMute.forEach((reqSection) => {
        const state = sections.find((o) => o.id === reqSection).state;
        if (state !== 'COMPLETE') {
          resp = true;
        }
      });

      // If we require the section to have certain values we will check now
      if (sectionConfig.hasValues) {
        const currentSection = sections.find((o) => o.id === section.id);
        if (currentSection.values[sectionConfig.hasValues]) {
          const checkValues = currentSection.values[sectionConfig.hasValues][0];
          delete checkValues._groupInstanceId;
          if (
            this._profileFormService.isEmpty(
              currentSection.values[sectionConfig.hasValues][0],
            )
          ) {
            resp = true;
          }
        }
      }
      return resp;
    }
    return false;
  }

  private _handleError(err: HttpErrorResponse): Observable<never> {
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${err.status}: ${err.message}`;
    }
    console.error(err);
    return throwError(() => errorMessage);
  }
}
