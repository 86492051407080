<div class="pwa-installer" *ngIf="canInstall">
  <div class="pwa-installer__wrapper">
    <svg-icon
      id="close"
      class="pwa-installer__close"
      (click)="close()"
    ></svg-icon>
    <div class="pwa-installer__logo">
      <svg-icon id="logo-nursing"></svg-icon>
    </div>
    <div class="pwa-installer__info">
      <h5>Save the app on your homescreen!</h5>
      <p>
        Download the
        <ng-container *ngIf="scope === 'tns'; else sng">Thornbury</ng-container
        ><ng-template #sng>Guild</ng-template> Portal app today and manage your
        application on the go.
      </p>
      <app-button
        className="mat-flat-button mat-medium-button mat-full-width mat-no-margin"
        id="pwa-install-button"
        text="Download here"
        (clicked)="install()"
      >
      </app-button>
    </div>
  </div>
</div>
